import axios from "axios";
import { rootProtectedPath, services } from "data/authData";
import { arrType, datatypes, inputTypes } from "data/globalData";
import moment from "moment";
export const mbolfsAllStoresPage = "stores";
export const mbolfsSelectedStorePage = "selected-store";

export const mbolfsSelectedStorepageUrl = `/${rootProtectedPath}/${services.mbo_stores.path}/${mbolfsSelectedStorePage}`;

export const addOrEditModelAction = { UPDATE: "UPDATE", CREATE: "CREATE" };

const CHANNEL_OPTIONS = [
	{
		name: "MBO",
		key: "MBO",
	},
	{
		name: "LFS",
		key: "LFS",
	},
];

const BRAND_OPTIONS = [
	{
		name: "RARE RABBIT",
		key: "RARE RABBIT",
		brand_code: "RR",
	},
	{
		name: "RAREISM",
		key: "RAREISM",
		brand_code: "RSM",
	},
	{
		name: "THOR",
		key: "THOR",
		brand_code: "THOR",
	},
	{
		name: "RAREONES",
		key: "RAREONES",
		brand_code: "RO",
	},
	{
		name: "RAREZ",
		key: "RAREZ",
		brand_code: "RZ",
	},
];

const SUB_BRAND_OPTIONS = [
	{
		name: "GUILD",
		key: "GUILD",
	},
	{
		name: "ARTICALE",
		key: "ARTICALE",
	},
	{
		name: "RUBY",
		key: "RUBY",
	},
];

const STORE_CATEGORY_OPTIONS = [
	{
		name: "APPARELS",
		key: "APPARELS",
	},
	{
		name: "ADDONS",
		key: "ADDONS",
	},
	{
		name: "FOOTWEAR",
		key: "FOOTWEAR",
	},
];

const REGION_OPTIONS = [
	{
		name: "NORTH",
		key: "NORTH",
	},
	{
		name: "SOUTH",
		key: "SOUTH",
	},
	{
		name: "EAST",
		key: "EAST",
	},
	{
		name: "WEST",
		key: "WEST",
	},
	{
		name: "CENTRAL",
		key: "CENTRAL",
	},
];

const GRADE_OPTIONS = [
	{
		name: "A",
		key: "A",
	},
	{
		name: "A+",
		key: "A+",
	},
	{
		name: "B",
		key: "B+",
	},
	{
		name: "FO",
		key: "FO",
	},
];

const OPERATION_MODE_OPTIONS = [
	{
		name: "OUTRIGHT",
		key: "OUTRIGHT",
	},
	{
		name: "SOR",
		key: "SOR",
	},
	{
		name: "N/A",
		key: "N/A",
	},
];

const SECONDARY_STOCK_OPTIONS = [
	{
		name: "YES",
		key: "YES",
	},
	{
		name: "NO",
		key: "NO",
	},
	{
		name: "N/A",
		key: "N/A",
	},
];

const TRADE_GROUP_OPTIONS = [
	{
		name: "INTER STATE",
		key: "inter_state",
	},
	{
		name: "LOCAL",
		key: "LOCAL",
	},
];

const SALES_TERM_OPTIONS = [
	{
		name: "GST: CGST + SGST",
		key: "gst_cgst_sgst",
	},
	{
		name: "GST: IGST",
		key: "gst_igst",
	},
	{
		name: "INTERSTATE SALES",
		key: "interstate_sale",
	},
	{
		name: "LOCAL SALES",
		key: "local_sales",
	},
];

const WH_OPTIONS = [
	{
		name: "MBO - BLR",
		key: "mbo_blr",
	},
	{
		name: "MBO - DELHI",
		key: "mbo_delhi",
	},
];

export const getColumnValue = (row, obj) => {
	if (row?.data_type === datatypes.DATE.key) {
		return moment(obj?.[row.key]).format("DD-MM-YYYY") || "--";
	} else if (row.input_type === inputTypes.MULTIPLE_SELECT.key) {
		return obj?.[row.key]?.join(",") || "--";
	} else {
		return obj?.[row.key] || "--";
	}
};

export const getColDefaultValue = (col) => {
	if (col?.input_type === inputTypes.ENTER.key) {
		return col?.data_type === datatypes.STRING.key
			? ""
			: col?.data_type === datatypes.NUMBER.key
			? 0
			: col?.data_type === datatypes.DECIMAL.key
			? 0.0
			: "";
	} else if (col?.input_type === inputTypes.SELECT.key) {
		return inputTypes.SELECT.key;
	} else if (col?.input_type === inputTypes.MULTIPLE_SELECT.key) return [];

	return "";
};

export const autoUpdateStoreBasicDetailsData = {
	pincode: {
		col: "state",
		val: async (triggerCol, triggerColVal, colToUpdate, options) => {
			if (triggerColVal.length > 5) {
				const res = await axios.get(`/api/store/get-state/${triggerColVal}`);
				if (res.status === 200 && res.data[0].state) {
					return res.data[0].state;
				} else {
					return "";
				}
			} else {
				return "";
			}
		},
	},
};

export const storeTypeDetails = [
	{
		name: "Channel",
		key: "channel",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 0,
		group: "",
		option_data: {
			type: arrType,
			list: CHANNEL_OPTIONS,
		},
	},
	{
		name: "Code",
		key: "code",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "BRAND",
		key: "brand",
		input_type: inputTypes.MULTIPLE_SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		option_data: {
			type: arrType,
			list: BRAND_OPTIONS,
		},
	},
	{
		name: "Sub Brand",
		key: "sub_brand",
		input_type: inputTypes.MULTIPLE_SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 2,
		group: "",
		option_data: {
			type: arrType,
			list: SUB_BRAND_OPTIONS,
		},
	},
	{
		name: "Store Category",
		key: "store_category",
		input_type: inputTypes.MULTIPLE_SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 3,
		group: "",
		option_data: {
			type: arrType,
			list: STORE_CATEGORY_OPTIONS,
			// getOptionLabel: (row) => {
			// 	return { name: row.label, key: row.value };
			// },
		},
	},
	{
		name: "Carpet Area",
		key: "carpet_area",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.NUMBER.key,
		sort_no: 4,
		group: "",
	},
	{
		name: "Site Name",
		key: "site_name",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 5,
		group: "",
	},
	{
		name: "Short Name",
		key: "short_name",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 5,
		group: "",
	},
	{
		name: "Customer",
		key: "customer",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 5,
		group: "",
	},
	{
		name: "City",
		key: "city",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 5,
		group: "",
	},
	{
		name: "State",
		key: "state",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 5,
		group: "",
	},
	{
		name: "Pincode",
		key: "pincode",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.NUMBER.key,
		sort_no: 5,
		group: "",
	},
	{
		name: "Region",
		key: "region",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 5,
		group: "",
		option_data: {
			type: arrType,
			list: REGION_OPTIONS,
		},
	},
	{
		name: "Grade",
		key: "grade",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 5,
		group: "",
		option_data: {
			type: arrType,
			list: GRADE_OPTIONS,
		},
	},
	{
		name: "Location URL",
		key: "location",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 5,
		group: "",
	},
	{
		name: "Address",
		key: "address",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 5,
		group: "",
	},
	{
		name: "Mode Of Operation",
		key: "mode_of_operation",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 5,
		group: "",
		option_data: {
			type: arrType,
			list: OPERATION_MODE_OPTIONS,
		},
	},
	{
		name: "Secondary Stock",
		key: "secondary_stock",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 5,
		group: "",
		option_data: {
			type: arrType,
			list: SECONDARY_STOCK_OPTIONS,
		},
	},
	{
		name: "Trade Group",
		key: "trade_group",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 5,
		group: "",
		option_data: {
			type: arrType,
			list: TRADE_GROUP_OPTIONS,
		},
	},
	{
		name: "Sales Term",
		key: "sales_term",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 5,
		group: "",
		option_data: {
			type: arrType,
			list: SALES_TERM_OPTIONS,
		},
	},
	{
		name: "Form Name",
		key: "form_name",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 5,
		group: "",
	},
	{
		name: "Final ASM",
		key: "final_asm",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 5,
		group: "",
	},
	{
		name: "Distributers",
		key: "distributers",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 5,
		group: "",
		option_data: {
			type: arrType,
			list: TRADE_GROUP_OPTIONS,
		},
	},

	{
		name: "WH",
		key: "wh",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 5,
		group: "",
		option_data: {
			type: arrType,
			list: WH_OPTIONS,
		},
	},

	{
		name: "Open Date",
		key: "open_date",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.DATE.key,
		sort_no: 5,
		group: "",
	},
	{
		name: "Base Stock",
		key: "base_stock",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 5,
		group: "",
	},
	{
		name: "Standard MDQ",
		key: "standard_mdq",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 5,
		group: "",
	},
	{
		name: "Distributer Billing Margin",
		key: "distributer_billing_margin",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.NUMBER.key,
		sort_no: 5,
		group: "",
	},
	{
		name: "Distributer Payment Margin",
		key: "distributer_payment_margin",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.NUMBER.key,
		sort_no: 5,
		group: "",
	},
	{
		name: "Retailer Billing Margin",
		key: "retailer_billing_margin",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.NUMBER.key,
		sort_no: 5,
		group: "",
	},
	{
		name: "Retailer Payment Margin",
		key: "retailer_payment_margin",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.NUMBER.key,
		sort_no: 5,
		group: "",
	},
];
