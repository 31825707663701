import { useState, useEffect } from "react";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import { DataGrid } from "@mui/x-data-grid";

import CircularProgressWithLabel from "../../ReusableComponents/CircularProgressWithLabel";
import { GlobalState } from "../../../context/GlobalContext";
import { brandList } from "../../../data/businessPlanningData";
import { BusinessPlanState } from "../../../context/BusinessPlanningContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import KeyboardDoubleArrowUpRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowUpRounded";
import dayjs from "dayjs";
import { getDatesofSeason } from "utils/getDatesofSeason";
import { roundDecimalValueUptoTwo } from "utils/roundDecimalValue";
import { calculateAsp, calculateDiscount, calculateRos } from "utils/calculateStoreData";
import { calculateStoreAge } from "utils/calculateStoreAge";
import { InLakhsDisplay } from "components/ReusableComponents/InLakhsDisplay";
import { custom, rootProtectedPath, services } from "data/authData";

const storeTablecolumns = [
	{ field: "store_name", headerName: "STORE NAME", headerAlign: "center", width: 210, align: "center" },
	{ field: "mapping_code", headerName: "STORE CODE", width: 90, headerAlign: "center", align: "center" },
	// { field: "total_count", headerName: "STORE CODE", width: 90, headerAlign: "center", align: "center" },
	{ field: "brand", headerName: "BRAND", width: 110, headerAlign: "center", align: "center" },
	{ field: "rsm_name", headerName: "RSM", width: 90, headerAlign: "center", align: "center" },

	{
		field: "age",
		headerName: "AGE",
		width: 60,
		headerAlign: "center",
		align: "center",
		renderCell: (params) => {
			return (
				<Box>
					{calculateStoreAge(params.row.open_date)}
					<span style={{ fontSize: "13px" }}>Y</span>
				</Box>
			);
		},
	},
	{ field: "total_qty", headerName: "TOTAL QTY", width: 90, headerAlign: "center", align: "center" },
	{
		field: "new_dis",
		headerName: "DIS",
		width: 70,
		headerAlign: "center",
		align: "center",
		renderCell: (params) => {
			// console.log(params);
			return <>{params.row.new_dis}%</>;
		},
	},
	// {
	// 	field: "new_st",
	// 	headerName: "ST",
	// 	width: 70,
	// 	headerAlign: "center",
	// 	align: "center",
	// 	renderCell: (params) => {
	// 		// console.log(params);
	// 		return <>{params.row.new_st}%</>;
	// 	},
	// },
	{
		field: "total_opt",
		headerName: "NEW OPTIONS",
		width: 95,
		headerAlign: "center",

		renderCell: (params) => {
			// console.log(params);
			return <>{params.row?.total_opt}</>;
		},
	},
	{
		field: "new_opt",
		headerName: "NEW OPTIONS",
		width: 95,
		headerAlign: "center",

		renderCell: (params) => {
			// console.log(params);
			return <>{params.row?.new_opt}</>;
		},
	},
	{
		field: "new_opt",
		headerName: "NEW OPTIONS",
		width: 95,
		headerAlign: "center",

		renderCell: (params) => {
			// console.log(params);
			return <>{params.row?.new_opt}</>;
		},
	},
	{ field: "new_asp", headerName: "ASP", width: 70, headerAlign: "center", align: "center" },
	{
		field: "new_nsv",
		headerName: "NSV",
		width: 80,
		headerAlign: "center",
		align: "center",
		renderCell: (params) => {
			// console.log(params);
			return <InLakhsDisplay number={params.row.new_nsv} sx={{ fontWeight: "normal" }} />;
		},
	},

	{ field: "store_grade", headerName: "GRADE", width: 80, headerAlign: "center", align: "center" },
	{ field: "city_tier", headerName: "TIER", width: 80, headerAlign: "center", align: "center" },
	{
		field: "stage",
		headerName: "Stage",
		headerAlign: "center",
		align: "center",
		width: 60,
	},

	{
		field: "status",
		align: "center",
		headerName: "STATUS",
		headerAlign: "center",
		width: 70,
		renderCell: (params) => {
			const stage = params.row.stage;
			const value = stage === custom.RSM.key ? 0 : stage === custom.NSM.key ? 50 : 100;
			return <CircularProgressWithLabel value={+value ? value : 0} />;
		},
	},
];

function StoresTable() {
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();
	const { selectedBp, setSelectedBp, selectedStore, setSelectedStore } = BusinessPlanState();

	const [loadingDailogue, setLoadingDailogue] = useState(false);

	const navigate = useNavigate();
	useEffect(() => {
		if (selectedBp && selectedBp.bpStores) {
			setAllStores(selectedBp.bpStores);
		}
	}, [selectedBp]);

	const [allStores, setAllStores] = useState([]);

	const autoCreateStoreSeasonWiseData = async (store) => {
		const { startDate, endDate } = getDatesofSeason(selectedBp.season, selectedBp.year);
		// const res = await axios.get(
		// 	`/api/store/get_cat_EBO_sale_season_data?startDate=${startDate}&endDate=${endDate}&storeCode=${store.mapping_code}`
		// );
		// const data = res.data[0];
		// const prvSeasonData = {
		// 	bp_store_id: store.id,
		// 	range: "6 M AVG",
		// 	total_mrp: data.PREVIOUS_SEASON_MRP,
		// 	revenue: data.PREVIOUS_SEASON_VALUE,
		// 	qty: data.PREVIOUS_SEASON_QTY,
		// 	asp: calculateAsp(data.PREVIOUS_SEASON_VALUE, data.PREVIOUS_SEASON_QTY),
		// 	dis: calculateDiscount(data.PREVIOUS_SEASON_MRP, data.PREVIOUS_SEASON_VALUE),
		// 	ros: calculateRos(data.PREVIOUS_SEASON_QTY),
		// 	rtv: 0,
		// 	st: 0,
		// };
		// const ltlSeasonData = {
		// 	bp_store_id: store.id,
		// 	range: "LTL AVG",
		// 	total_mrp: data.LTL_MRP,
		// 	revenue: data.LTL_VALUE,
		// 	qty: data.LTL_QTY,
		// 	asp: calculateAsp(data.LTL_VALUE, data.LTL_QTY),
		// 	dis: calculateDiscount(data.LTL_MRP, data.LTL_VALUE),
		// 	ros: calculateRos(data.LTL_QTY),
		// 	rtv: 0,
		// 	st: 0,
		// };

		return [
			{ bp_store_id: store.id, range: "NEW TARGET", is_new_target: true },
			{ bp_store_id: store.id, range: "DERIVED TARGET" },
		];
	};
	const autoCreateCategoryTableRows = async (store) => {
		const { startDate, endDate } = getDatesofSeason(selectedBp.season, selectedBp.year);

		const categoriesPromise = new Promise(async (resolve, reject) => {
			await axios
				.get(`/api/item_master/get_cat_by_brand?brand=${store.brand}`)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					resolve("error");
				});
		});
		const historicalDataWithDispatchPromise = new Promise(async (resolve, reject) => {
			await axios
				.get(
					`/api/store/get_cat_EBO_sale_season_data_with_dispatch?startDate=${startDate}&endDate=${endDate}&storeCode=${store.mapping_code}&brand=${store.brand}&season=${selectedBp.name}`
				)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					resolve("error");
				});
		});

		const [categories, historicalDataWithDispatch] = await Promise.all([categoriesPromise, historicalDataWithDispatchPromise]);

		if (
			categories !== "error" &&
			historicalDataWithDispatch !== "error" &&
			historicalDataWithDispatch.length &&
			historicalDataWithDispatch.length !== 0
		) {
			const dispatch_map = new Map();

			historicalDataWithDispatch.forEach((ele, i) => {
				dispatch_map[ele.DEPARTMENT] = i;
			});

			// console.log("hist", categories, historicalDataWithDispatch);

			const storeCategories = [];
			for (let i = 0; i < categories.length; i++) {
				let newObj = {};
				const category = categories[i];
				// console.log(historicalData[map[category.DEPARTMENT]]);
				if (dispatch_map[category.DEPARTMENT] >= 0) {
					let ltlData = historicalDataWithDispatch[dispatch_map[category.DEPARTMENT]];
					newObj = {
						ltl_total_mrp: ltlData.TOTAL_MRP,
						ltl_nsv: ltlData.REALISED_VALUE,
						ltl_qty: ltlData.BILL_QUANTITY,
						ltl_opt: ltlData.DISTINCT_COLOR_COUNT,
						ltl_inventory_qty: ltlData.INV_QTY,
						ltl_asp: calculateAsp(ltlData.REALISED_VALUE, ltlData.BILL_QUANTITY),
						ltl_dis: calculateDiscount(ltlData.TOTAL_MRP, ltlData.REALISED_VALUE),
						ros: calculateRos(ltlData.BILL_QUANTITY),
						ltl_st: ltlData.ST,
						ltl_inventory_qty: ltlData.INV_QTY,
					};
				}
				storeCategories.push({
					...newObj,
					bp_store_id: store.id,
					category: category.DEPARTMENT,
					division: category.DIVISION,
				});
			}
			return storeCategories;
		} else throw new Error("Unable to load data");
	};
	const handleScrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};
	const handleGridRowClick = async (params, e) => {
		const row = params.row;
		console.log(row);
		setLoadingDailogue(true);
		try {
			if (row.total_count === 0) {
				const storeCategories = await autoCreateCategoryTableRows(row);
				const storeSeasonWiseData = await autoCreateStoreSeasonWiseData(row);
				const body = {
					storeCategories,
					storeSeasonWiseData,
					storeProjection: { stage: "RSM", bp_store_id: row.id },
				};
				console.log(body);
				await axios.post("/api/business_plan/bulk_create_store_data", body).then((res) => {
					const storeDetails = { ...row, ...res.data };
					setSelectedStore(storeDetails);
					// set selected Bp after creating
					setSelectedBp({
						...selectedBp,
						bpStores: selectedBp.bpStores.map((store) => {
							if (store.id === row.id) return storeDetails;
							else return store;
						}),
					});
				});
			} else {
				await axios.get(`/api/business_plan/get_store_data?id=${row.id}`).then((res) => {
					setSelectedStore(res.data);
					console.log(res.data);
					// set selected Bp after creating
					setSelectedBp({
						...selectedBp,
						bpStores: selectedBp.bpStores.map((store) => {
							if (store.id === row.id) return res.data;
							else return store;
						}),
					});
				});
			}
			setLoadingDailogue(false);
			handleScrollToTop();
			navigate(`/${rootProtectedPath}/${services.business_plan.path}/store?id=${row.id}`);
		} catch (error) {
			setSnackBarOpen(true);
			console.log(error);
			const response = error.response;
			if (response && response.data && response.data.status === "error")
				setSnackBar({ ...snackBar, message: response.data.error, severity: "error" });
			else setSnackBar({ ...snackBar, message: error.message, severity: "error" });
			setLoadingDailogue(false);
		}
	};

	return (
		<Box display="flex" width="100%" justifyContent="space-around" flexWrap="wrap">
			{/* heading */}
			<Box display="flex" width="100%" pb={3} alignItems="center" justifyContent="space-between">
				<Box display="flex" alignItems="center">
					<Typography variant="h5" p={2}>
						{selectedBp.name} / {selectedBp.channel} - Stores
					</Typography>
				</Box>
				<Button
					onClick={handleScrollToTop}
					startIcon={<KeyboardDoubleArrowUpRoundedIcon fontSize="large" />}
					variant="contained">
					All Plans
				</Button>
			</Box>

			{/* Brands Table */}
			<Stack width="100%" height={500}>
				<DataGrid
					rows={allStores}
					columns={storeTablecolumns}
					getRowClassName={(params) => (params.row.is_completed ? (params.row.total_count === 0 ? "yellow" : "green") : "")}
					sx={{
						minHeight: 300,
						maxHeight: 500,
						"& .MuiDataGrid-row": {
							borderBottom: "1px solid lightgray",
							cursor: "pointer",
						},
					}}
					rowHeight={40}
					showCellVerticalBorder
					onRowClick={(params, e) => handleGridRowClick(params, e)}
					initialState={{
						pagination: {
							paginationModel: { pageSize: 10 },
						},
						columns: {
							columnVisibilityModel: {
								total_pcs: false,
								ltl_st: false,
								new_st: false,
							},
						},
					}}
					disableRowSelectionOnClick
					pageSizeOptions={[20, 50]}
				/>
			</Stack>
			{/* loading modal */}
			<Dialog onClose={() => {}} open={loadingDailogue}>
				<Box width="200px" height="100px" display="flex" alignItems="center" justifyContent="center">
					<CircularProgress />
				</Box>
			</Dialog>
		</Box>
	);
}

export default StoresTable;
