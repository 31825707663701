import { Box, Button, Divider, InputLabel, Stack, Typography } from "@mui/material";
import { addOrEditModelAction, getColumnValue, mbolfsAllStoresPage } from "../../../data";
import { statusOptions } from "data/pimData";
import { useEffect, useState } from "react";
import BasicDetailsModal from "./BasicDetails/AddOrEditModal/AddOrEditModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GlobalState } from "context/GlobalContext";
import axios from "axios";

function BasicDetails() {
	const [selectedStore, setSelectedStore] = useState([]);
	const [basicDetailModal, setBasicDetailModal] = useState(false);
	let [searchParams, setSearchParams] = useSearchParams();
	const { setSnackBar, snackBar, setSnackBarOpen, loginUser, setLoadingOpen } = GlobalState();
	const navigate = useNavigate();

	const goToStoresPage = () => {
		navigate(mbolfsAllStoresPage);
	};

	useEffect(() => {
		console.log("selectedSTORE", selectedStore);

		const fetch = async (store_code) => {
			try {
				setLoadingOpen(true);
				const res = await axios.get(`/api/mbo_store_v2/store_by_id/${store_code}`);
				console.log(res);
				setSelectedStore(res.data);
				setLoadingOpen(false);
			} catch (error) {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "Unable to get store details",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setLoadingOpen(false);
			}
		};
		let store_code = searchParams.get("store");
		if (store_code) fetch(store_code);
		else {
			setSelectedStore(null);
		}
	}, [searchParams]);

	if (selectedStore)
		return (
			<Stack p={1} width="98%" margin="auto" spacing={2}>
				<Box display="flex" justifyContent="space-between" pt={1} pb={2}>
					<Typography variant="h5">
						{/* <Button startIcon={<ArrowBackIosIcon fontSize="20" />} size="large" onClick={() => navigate(-1)} /> */}
						Store Details - {selectedStore?.code}
					</Typography>
					{/* <Box>
						<Button variant="contained">Publish</Button>
					</Box> */}
					<Typography color={selectedStore.status === statusOptions.PUBLISHED.key && statusOptions.PUBLISHED.color}>
						{selectedStore.status === statusOptions.PUBLISHED.key && statusOptions.PUBLISHED.key}
					</Typography>
				</Box>

				{/* Basic Details */}
				<Box display="flex" flexWrap="wrap" columnGap={"10px"} rowGap="10px" width="100%">
					<Box display="flex" flexWrap="wrap" width="100%" justifyContent="space-between">
						<Typography variant="button">Basic Details</Typography>
						<Box>
							<Button
								size="small"
								disabled={selectedStore.status === statusOptions.PUBLISHED.key}
								variant="contained"
								onClick={() => setBasicDetailModal(true)}>
								Edit
							</Button>
						</Box>
						<Divider sx={{ width: "100%" }} />
					</Box>
				</Box>
				{/* Besic details add or edit modal */}
				{basicDetailModal && (
					<BasicDetailsModal
						modal={basicDetailModal}
						setModal={setBasicDetailModal}
						type={addOrEditModelAction.UPDATE}
						defaultData={selectedStore?.eboStoreBasicDetails}
						// handleUpdateDetails={handleUpdateDetails}
						selectedStore={selectedStore}
						setSelectedStore={setSelectedStore}
					/>
				)}
			</Stack>
		);
	else
		return (
			<Stack display="flex" justifyContent="center" alignItems="center" height="100%" spacing={1}>
				<Typography variant="h5" p={2}>
					---- No Store Selected ----
				</Typography>
				<Button variant="contained" sx={{ textTransform: "capitalize" }} onClick={goToStoresPage}>
					Go to All Stores
				</Button>
			</Stack>
		);
}

export default BasicDetails;
