export const mboStoreDrawerElements = {
	store_details: {
		name: "Store Details",
		key: "store_details",
		display: true,
	},
	finance_details: {
		name: "Finance Details",
		key: "finance_details",
		display: true,
	},
	// employee_details: {
	// 	name: "Employee Details",
	// 	key: "employee_details",
	// 	display: true,
	// },
};
