import { Box, Button, Dialog, DialogActions, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DownloadIcon from "@mui/icons-material/Download";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { GlobalState } from "context/GlobalContext.js";
import CreateNewModal from "./CreateNewModal/CreateNewModal";
import { eboSelectedStorepageUrl } from "../data";
import { publishTableColumn } from "data/globalData";
import { statusOptions } from "data/pimData";
import FileSaver from "file-saver";
import { EboStoreState } from "context/EboStoreContext";

function EboStoresList() {
	const navigate = useNavigate();
	const [stores, setStores] = useState([]);
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();
	const { selectedStore, setSelectedStore } = EboStoreState();
	const { loadingOpen, setLoadingOpen } = GlobalState();

	const [createModalOpen, setCreateModalOpen] = useState(false);
	const [publish, setPublish] = useState(false);
	const [storeSelected, setStoreSelected] = useState();

	const createModalClick = () => {
		setCreateModalOpen(true);
	};
	const handleStoreSelect = (storeSelected) => {
		setSelectedStore(storeSelected);
		navigate(`${eboSelectedStorepageUrl}?store=${storeSelected.store_code}`);
	};

	const fetchStores = async () => {
		setLoadingOpen(true);
		await axios
			.get("/api/ebo_store_v4/get-ebo-stores")
			.then((res) => {
				if (res.status === 200) {
					console.log(res.data);
					setStores(res.data);
					setLoadingOpen(false);
				}
			})
			.catch((err) => {
				console.log("error", err);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "unable to fetch stores",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setLoadingOpen(false);
			});
	};
	const publishStore = async (id, body) => {
		setLoadingOpen(true);
		await axios
			.put(`/api/ebo_store_v4/publish_store/${id}`, body)
			.then((res) => {
				if (res.status === 200) {
					console.log(res.data);
					let filterStore = stores.filter((store) => store.store_code !== id);
					filterStore.unshift(body);
					setStores(filterStore);
					setLoadingOpen(false);
					setPublish(false);
				}
			})
			.catch((err) => {
				console.log("error", err);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "unable to fetch stores",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setLoadingOpen(false);
			});
	};

	useEffect(() => {
		fetchStores();
	}, []);

	const handleDownloadClick = async () => {
		setLoadingOpen(true);
		try {
			await axios.get("/api/ebo_store_v4/download_stores", { responseType: "blob" }).then((res) => {
				console.log(res);
				const url = URL.createObjectURL(res.data);
				FileSaver.saveAs(url, "Ebo_store_details.xlsx");
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "Downloaded the all store details",
					severity: "success",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			});
		} catch (error) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "unable to fetch stores",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
		}
		setLoadingOpen(false);
	};

	const handleConfirmPublish = () => {
		const body = {
			...storeSelected,
			published_date: new Date(),
			status: statusOptions.PUBLISHED.key,
		};
		publishStore(storeSelected.store_code, body);
	};

	const handlePublishClick = (e, row) => {
		e.stopPropagation();
		setStoreSelected(row);
		setPublish(true);
	};

	const columns = [
		{
			field: "store_code",
			headerName: "STORE CODE",
			width: 150,
		},
		{
			field: "store_name",
			headerName: "REPORTING NAME",
			width: 250,
			// renderCell: (params) => params.row?.storeBasicDetails?.store_full_name.toUpperCase(),
		},
		{
			// field: "state",
			headerName: "STATE",
			width: 170,
			renderCell: (params) => params.row?.eboStoreBasicDetails?.state.toUpperCase(),
		},
		{
			field: "city",
			headerName: "CITY",
			width: 170,
			renderCell: (params) => params.row?.eboStoreBasicDetails?.city.toUpperCase(),
		},
		{
			field: "pincode",
			headerName: "PINCODE",
			width: 170,
			renderCell: (params) => params.row?.eboStoreBasicDetails?.pincode,
		},
		{
			field: "brand",
			headerName: "BRAND",
			width: 170,
			renderCell: (params) => params.row?.eboStoreTypeDetails[0]?.brand.toUpperCase(),
		},
		publishTableColumn(handlePublishClick, false),
	];

	const handleModalclose = () => {
		setPublish(false);
	};

	return (
		<Box direction="row" width="100%" justifyContent="space-around" p={2} boxSizing={"border-box"}>
			<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} pt={2}>
				<Stack direction={"row"}>
					<Button
						startIcon={<ArrowBackIosIcon fontSize="20" />}
						size="large"
						onClick={() => navigate("/explore/ebo_stores")}
					/>
					<Typography variant="h4" align={"left"}>
						STORE MASTER
					</Typography>
				</Stack>
				<Stack direction={"row"} display={"flex"} justifyContent="space-evenly" width={"30%"} justifyItems={"flex-end"}>
					<Button
						autoFocus
						variant="contained"
						onClick={() => handleDownloadClick()}
						startIcon={<DownloadIcon />}
						size="medium">
						Download
					</Button>
					<Button autoFocus variant="contained" onClick={createModalClick} startIcon={<AddIcon />} size="medium">
						Create New Store
					</Button>
				</Stack>
			</Stack>
			<Stack pt={3} height={420}>
				<DataGrid
					getRowId={(row) => row.store_code}
					rows={stores}
					columns={columns}
					sx={{
						"& .MuiDataGrid-row": {
							cursor: "pointer",
						},
					}}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 10,
							},
						},
					}}
					pageSizeOptions={[10, 20, 30]}
					onRowClick={({ row }) => handleStoreSelect(row)}
				/>
				{createModalOpen && <CreateNewModal modal={createModalOpen} setModal={setCreateModalOpen} fetchStores={fetchStores} />}
			</Stack>
			{publish && (
				<Dialog maxWidth="sm" fullWidth open={publish} onClose={handleModalclose}>
					<Box p={2}>
						<Typography variant="h6">{`Do you want to publish ${storeSelected.store_name} store`}</Typography>

						<DialogActions sx={{ pt: 2 }}>
							<Button variant="outlined" sx={{ justifySelf: "center" }} onClick={handleModalclose}>
								Cancel
							</Button>
							<Button variant="contained" onClick={handleConfirmPublish} sx={{ justifySelf: "center" }} type="submit">
								Publish
							</Button>
						</DialogActions>
					</Box>
				</Dialog>
			)}
		</Box>
	);
}

export default EboStoresList;
