import moment from "moment";

export const supplierDrawerElements = {
	company_info: {
		name: "Company Info",
		key: "company_info",
		display: true,
	},
	all_factories: {
		name: "Factories",
		key: "all_factories",
		display: true,
	},
	factory: {
		name: "Factory",
		key: "factory",
		display: false,
	},
	agreements: {
		name: "Agreements",
		key: "agreements",
		display: true,
	},
	status: {
		name: "Approval Status",
		key: "Status",
		display: true,
	},
};
export const foreignKeys = { supplier: "supplier_id", factory: "factory_id" };

export const supplierUrlParamName = "tab";
export const supplierIdParamName = "sup_id";
export const factoryUrlParamName = "fact";

export const companyBasicDetailColumns = {
	company_name: { name: "Company Name", key: "company_name" },
	name_of_owner: { name: "Director/Owner Name", key: "name_of_owner" },
	company_type: { name: "Company Type", key: "company_type" },

	company_establishment_date: { name: "Company Establishment Date", isDate: true, key: "company_establishment_date" },
	effective_with_thor_date: {
		name: "Effective Date with the House Of Rare",
		isDate: true,
		key: "effective_with_thor_date",
	},
	// pan_no: { name: "PAN Number" },
	gst_no: { name: "Office GST Number", key: "gst_no" },
	website_link: { name: "Website Link", key: "website_link" },
	gmap_location_link: { name: "Location Link (Google Maps)", key: "gmap_location_link" },
	monthly_capacity: { name: "Monthly Capacity", key: "monthly_capacity" },
	capacity_for_thor: { name: "Monthly Capacity for The House Of Rare", key: "capacity_for_thor" },
	// no_of_pieces: { name: "No of Pieces Per Month" },
	no_of_labour: { name: "No of Labour", key: "no_of_labour" },
	industry_type: { name: "Industry Type", key: "industry_type" },
	supplier_nature: { name: "Nature of Supplier", key: "supplier_nature" },
	product_division: { name: "Product Divisions", key: "product_division" },
	product_section: { name: "Product Section", key: "product_section" },
	product_type: { name: "Product Type", key: "product_type" },
	product_categories: { name: "Product Categories", key: "product_categories" },
};
export const msmeIndustryColumns = {
	industry_cert_issue_date: { name: "Issue Date", key: "industry_cert_issue_date", isDate: true },
	category_of_unit: { name: "Category of the Unit ", key: "category_of_unit" },
	entrepreneur_memorandum_no_part_2: {
		name: "Entrepreneurs Memorandum Number - PART –II",
		key: "entrepreneur_memorandum_no_part_2",
	},
};
export const industryTypeValues = {
	msme: "MSME",
	large_scale: "LARGE SCALE",
};
export const supplierAgreementTypes = {
	vendor_agreement: { name: "Vendor Agreemnt", key: "vendor_agreement", display: (industryType) => true },
	decleration_agreemnt: {
		name: "Declaration Agreemnt",
		key: "decleration_agreemnt",
		display: (industryType) => industryType === industryTypeValues.msme,
	},
};
export const divisions = {
	apparels: "APPARELS",
	addons: "ADDONS",
	footwear: "FOOT WEAR",
	trims: "TRIMS",
	fabric: "FABRIC",
};
export const productSections = {
	men: "MEN",
	women: "WOMEN",
	kids: "KIDS",
};
export const productTypeValues = {
	knits: "KNITS",
	woven: "WOVEN",
	"N/A": "N/A",
};
export const paymentTermColumns = {
	supplier_type: { name: "Supplier Type ", key: "supplier_type" },
	payment_mode: { name: "Payment Mode ", key: "payment_mode" },
	payment_term: { name: "Payment Term", key: "payment_term" },
};
export const paymentModeOptions = {
	NEFT: { name: "NEFT ", key: "NEFT" },
	payment_mode: { name: "RTGS ", key: "RTGS" },
	payment_term: { name: "CHEQUE", key: "CHEQUE" },
};
export const companyRegistredAddressDetailColumns = {
	registred_office_address: { name: "Registred Address ", isAddress: true, key: "registred_office_address" },
	registred_office_state: { name: "State", key: "registred_office_state" },
	registred_office_city: { name: "City", key: "registred_office_city" },
	registred_office_pin_code: { name: "PinCode", key: "registred_office_pin_code" },
};
export const companyCommunicationAddressDetailColumns = {
	communication_office_address: {
		name: "Communication Address",
		isAddress: true,
		key: "communication_office_address",
	},
	communication_office_state: { name: "State", key: "communication_office_state" },
	communication_office_city: { name: "City", key: "communication_office_city" },
	communication_office_pin_code: { name: "PinCode", key: "communication_office_pin_code" },
};
export const financialDetailsColumns = {
	pan_no: { name: "Pan Number" },
	bank_name: { name: "Bank Name" },
	bank_ifsc_code: { name: "IFSC Code" },
	bank_acc_no: { name: "Account Number" },
	micr_code: { name: "MICR Code" },
	rtgs_code: { name: "RTGS Code" },
};
// export const companyBasicDetailDummyValues = {
// 	company_name: "Company1",
// 	name_of_owner: "Owner1",
// 	company_type: "LTD",
// 	industry_type: "LARGE SCALE",
// 	supplier_nature: "MANUFACTURER",
// 	company_establishment_date: new Date(),
// 	gst_no: "23frw54234",
// 	website_link: "https://www.google.com",
// 	gmap_location_link: "https://www.google.com",
// 	monthly_capacity: 300,
// 	capacity_for_thor: 80,
// 	no_of_pieces: 300,
// 	no_of_labour: 500,
// 	labour_on_company_roll_count: 240,
// 	labour_on_contract_count: 260,
// 	registred_office_address: "Registred Address 1,14 ,Near Electronic city Flyover",
// 	registred_office_state: "Karnataka",
// 	registred_office_city: "Bangalore",
// 	registred_office_pin_code: "899293",
// 	communication_office_address: "Communication Address,14 ,Near Electronic city Flyover",
// 	communication_office_state: "Karnataka",
// 	communication_office_city: "Bangalore",
// 	communication_office_pin_code: "234567",
// 	pan_no: "123456788",
// 	bank_name: "SBI",
// 	bank_ifsc_code: "KARB12345",
// 	bank_acc_no: "1234567899",
// 	micr_code: "dsf32",
// 	rtgs_code: "dfg23134",
// };
export const companyBasicDetailDummyValues = {
	company_name: "",
	name_of_owner: "",
	company_type: "",
	industry_type: "",
	supplier_nature: "",
	company_establishment_date: new Date(),
	gst_no: "23frw54234",
	website_link: "",
	gmap_location_link: "",
	monthly_capacity: 0,
	capacity_for_thor: 0,
	no_of_pieces: 0,
	no_of_labour: 0,
	labour_on_company_roll_count: 0,
	labour_on_contract_count: 0,
	registred_office_address: "",
	registred_office_state: "",
	registred_office_city: "",
	registred_office_pin_code: "",
	communication_office_address: "",
	communication_office_state: "",
	communication_office_city: "",
	communication_office_pin_code: "",
	pan_no: "",
	bank_name: "",
	bank_ifsc_code: "",
	bank_acc_no: "",
	micr_code: "",
	rtgs_code: "",
	supplierContactPersons: [],
	supplierFiles: [],
	supplierComplianceCertificates: [],
	supplierFactories: [],
	supplierApproval: [],
	supplierAgreement: [],
};
export const EditCompanyDetailsModalTypes = { edit: "EDIT", onbarding: "ONBOARDING" };

export const labourTableColumns = ["labour_on_company_roll_count", "labour_on_contract_count"];

export const fileData = {
	name: "",
	url: "",
	type: "select",
};
export const complianceCertDefData = {
	compliance_certificate_issuer: "",
	compliance_certificate_issue_date: moment().format("YYYY-MM-DD"),
	compliance_certificate_exp_date: moment().format("YYYY-MM-DD"),
};

export const fileRootPaths = {
	supplier: "suppliers",
};

export const fileTypesData = [
	{ name: "Industry Type Certification", Key: "INDUSTRY_CERT" },
	{ name: "Pancard", Key: "PANCARD" },
	{ name: "GST Certificate", Key: "GST_CERT" },
	{ name: "Blank Cheque Photo Copy", Key: "BLANK_CHEQUE_PHOTO" },
];
export const fileTypesDataObj = {
	INDUSTRY_CERT: { name: "Industry Type Certification", Key: "INDUSTRY_CERT" },
	PANCARD: { name: "Pancard", Key: "PANCARD" },
	GST_CERT: { name: "GST Certificate", Key: "GST_CERT" },
	BLANK_CHEQUE_PHOTO: { name: "Blank Cheque Photo Copy", Key: "BLANK_CHEQUE_PHOTO" },
};
export const fileTableColumns = {
	name: { name: "Name", key: "Name" },
	type: { name: "File Type", key: "type" },
};
export const complianceCertColumns = {
	compliance_certificate_issuer: { name: "Issuer", key: "compliance_certificate_issuer" },
	compliance_certificate_issue_date: { name: "Issued Date", key: "compliance_certificate_issue_date", isDate: true },
	compliance_certificate_exp_date: { name: "Expiry Date", key: "compliance_certificate_exp_date", isDate: true },
};
export const contactPersonColumns = {
	department: { name: "Department", key: "department" },
	name: { name: "Name", key: "name" },
	email: { name: "Email", key: "email" },
	position: { name: "Position", key: "position" },
	gender: { name: "Gender", key: "gender" },
	phone_no: { name: "Phone No", key: "phone_no" },
};
export const contactPersonData = {
	department: "select",
	name: "",
	email: "",
	position: "",
	gender: "select",
	phone_no: "",
};

export const factoryBasicDetailColumns = {
	name: { name: "Factory Name", key: "name" },
	factory_liscence_no: { name: "Factory Liscence No", key: "factory_liscence_no" },
	factory_liscence_expiry_date: { name: "Liscence Expiry Date", key: "factory_liscence_expiry_date", isDate: true },
	factory_liscence_issue_date: { name: "Liscence Issue Date", isDate: true, key: "factory_liscence_issue_date" },
	factory_gst_no: { name: "GST NO", key: "factory_gst_no" },
	gmap_location_link: { name: "Location Link (Google Maps)", key: "gmap_location_link" },
};
export const factoryAddressDetailColumns = {
	factory_address: { name: "Factory Address ", key: "factory_address", isAddress: true },
	factory_state: { name: "State", key: "factory_state" },
	factory_city: { name: "City", key: "factory_city" },
	factory_pin_code: { name: "PinCode", key: "factory_pin_code" },
};

export const agreementData = {
	company_name: { name: "Company Name" },
};
