import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// import { PowerBIEmbed } from "powerbi-client-react";
// import { models } from "powerbi-client";

const EcomReports = () => {
	return (
		<Box maxWidth="1200px" margin="auto">
			<Typography textAlign="center" p={2} variant="h6">
				Report
			</Typography>
			{/* <PowerBIEmbed 
				// embedConfig={{
				// 	type: "report", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
				// 	id: "93cb86da-b78b-46cf-9171-bb6fd7494f77",
				// 	embedUrl:
				// 		"https://app.powerbi.com/reportEmbed?reportId=93cb86da-b78b-46cf-9171-bb6fd7494f77&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwiZGlzYWJsZUFuZ3VsYXJKU0Jvb3RzdHJhcFJlcG9ydEVtYmVkIjp0cnVlfX0%3d",
				// 	accessToken:
				// 		"H4sIAAAAAAAEACWTt66EVgAF_-W1WCInSy5g4S4554685AwLlv_dz3J_qpk5f_9Y6d1PafHz5w9ce2eGT1I7sQ6zOxiMn5-x1qO-GOqiE_pCgau7kOfu6C-weKG3-1CRPtFTXVQq1IztkaSQRz1nLrDGboTqQirufxZhHizEJoxwEL_uAdBa9vrkoJwERWrOPi41dESrfQBLv7aXrGLNVAKhcsp5gPusfaNIvicdgUBSYJGvZOxJdtC2ste0UfMy2SSbF7NFE3r1Ix_GnJVAWMMH2ddD2dYbHKZRlI3PXnC-B4qH4VGSzNx4NlFKphxYpjekmveJFJkFnZuMwpTGyrg94nHL8UCde2UtG1rr0SmbLTeYccRvL5TQ7dbBF3taHhBQl4fZO3BH42yrJxyAhJXTlk6bSdrPyl1vQwXm_XLKPkDhMabXTgZyylzP_WHhtRKzLv54aNLyzSkiMeomRTvc5cxiUzgsxSrCpGs6eX5Wx10p8Q12tl5SADaXyYVuK8sgoltvHP00W9R2T7JGf0fscBI-lcsRZMEX5HR2gC_vl0RB1m7MWo9LywZb5mn40EJr4OMEu8uquNRzynlrlKcdcc11UUBnyGHw5KWJBvruNi9fjaiBZeWYr1qQnveZ9lO2Tp_thgEGcxcTFEJMVWm3aRXOnaiNp3SF6n0090icOu-xQzSbrEbbU5XcfRyhNh0nxNg1QFENAoXMzJ819DGYwBrWs23aAklnjd7L0kOtjPIng2ZUKOPBxfJ6cCVP5vA-3hGn0BQpYAy1JZTF5bg8EiRfO0kxRb3uZC8Nj1023KgBo2VXW7apmD85A1ZWxsowYp1sYDyvCrnqy_bPWy90BBYXaQ14io2VZTpka7SZnBwIeeZxWHJiZuvMwlcxbJrZ797tlxvk60pf9tefxUVLNYLEHxeSDe6vv37--Hmt97xPann_3szDZIUQQdAGmFmnpNg78fJNHroOI_pslky27wKZgvHZIO8JO9qNzzA9HwY4lsYEKtsYi1ljzvfwerbX-gGlasryBzUwe26RWTt3xTj6NOr-vBSkIHD1-G4ohWHgbhvFHBW9k5jHSwWIU6tlzITqTSML2IDbBG6aYTCM9nIBYFShdRg0TJhN7gJHXTcSeiHi88WPwpiZqmvC-OjU4usFdk-ar0JkbT70GJwCdliCuoJNfzgIdOMPQG4Gr3-fh4MH55kM058vKT3ZPEamuRWHunYU5hQprputMRukUXPWEo-qi3Zz3NO9-uOaJCXWRdFY1BVSL4vrivg3Eh-aSl5zbWSz_8d8z59ylYNfygFt0BvFWAGGhZwqhvN74tv_ZbhNPab7sZa_sxzBiBYZ6Awj9ZM2CNH8vruumXkEIUQU9YzsMiQx3XHpKJfoJchfybYerZEsR8zKocMMv9pAouHQ_WAh-zVPChcuxi40USHAh-ZciZzrBP2-H5Z1v8rnsRV0KKHua6TRJyGic9ZM3v_SYXAfPBbBou4OPVPSgrFDjm3kVy2X-pJ4Y7ghw96TfEcTOsQdH9oi-3er_tp5pN8UEwGTX_mINPTM64kZ_fp74ICVY6Px_YUyB8fAeNwprZNZO7YKA5XamsMD_IpxxC99Xo8rND30wgVTHcOQtMwPbyUNQshHfFMNOSCdkap-GH1riJrspZnhjwptjNbybRzdsi93UoB3L40z_pPxz7-_yQNZWgYAAA==.eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZXhwIjoxNzA1MDQ2MzkzLCJhbGxvd0FjY2Vzc092ZXJQdWJsaWNJbnRlcm5ldCI6dHJ1ZX0=",
				// 	tokenType: models.TokenType.Aad, // Use models.TokenType.Aad for SaaS embed
				// 	settings: {
				// 		// panes: {
				// 		// 	filters: {
				// 		// 		expanded: false,
				// 		// 		visible: false,
				// 		// 	},
				// 		// },
				// 		background: models.BackgroundType.Transparent,
				// 	},
				// }}
				// eventHandlers={
				// 	new Map([
				// 		[
				// 			"loaded",
				// 			function () {
				// 				console.log("Report loaded");
				// 			},
				// 		],
				// 		[
				// 			"rendered",
				// 			function () {
				// 				console.log("Report rendered");
				// 			},
				// 		],
				// 		[
				// 			"error",
				// 			function (event) {
				// 				console.log(event.detail);
				// 			},
				// 		],
				// 		["visualClicked", () => console.log("visual clicked")],
				// 		["pageChanged", (event) => console.log(event)],
				// 	])
				// }
				// cssClassName={"reportClass"}
				// getEmbeddedComponent={(embeddedReport) => {
				// 	window.report = embeddedReport;
				// }}
			// 	embedConfig={{
			// 		type: "report", // Supported types: report, dashboard, tile, visual, and qna.
			// 		id: "93cb86da-b78b-46cf-9171-bb6fd7494f77",
			// 		embedUrl:
			// 			"https://app.powerbi.com/reportEmbed?reportId=93cb86da-b78b-46cf-9171-bb6fd7494f77&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwiZGlzYWJsZUFuZ3VsYXJKU0Jvb3RzdHJhcFJlcG9ydEVtYmVkIjp0cnVlfX0%3d",
			// 		accessToken:
			// 			"H4sIAAAAAAAEAB2Uxw6sWAJD_-VtGYlcwEi9IOec2ZGKHIp0C1rz71PdkpdeWEe2__7j5Pe45NWf__7xHbZfOKdR--G1naraFPM6Sm2h5GeiBEa02hjT35om8EERcUF_6LYA6UDiERItt0nENzVqq9ZiTiu29Y6CPuz-fW359tDnqj1RWp5RlyleY372Dog1fXfTY9IMvrPBGamHllIIxYCPVNE0ZO-rKXuAKZxRlxIuuQO-4319OvFnnt6D0-tjaDjYYUY9nDzAlmOHTQ50zmDtqKBwxHm02cqzmXM2mU80Q7gduGqs7vGQdayzZl77HkyURkiNuyVDzCw8PruOLSjirkaEZZFJAKp2zL3WVCqYz1b_UKJukQf3BvGRX8kp3HPUnh_IdhTz-5mqiR6HBXfvA4MD9X7pSyiDcdenLKD273VzSiLiJQFKj1Hsz6gRb4fvwuHrxDeRQugVftfJOEgahkRBzDD1VV-cjTXXV10NE5mFZPD7d2YEylXvOtvqXHaRvUEAqNBgF_eFtqciZq_72BSmd5R0UlSSlmSK587nYcpWM2U9jfs0ftKPTIZgoSMKwGZQt_f0SfKZsNSf_hNOuK2q1Iw7TIsshUa-ABZeHMKefbmvoQNrzGAV8OougxqU_F55l4lQ2oIK3LGC8clikz6HFX5kAl0kwE4oc48BSjJkglyKcQe2ZIcFm61vbsZiw-0yFZ0OuYKgwkEsHjhXWzImxnx5T3hCNg2jSYuWqj7b6C4H1iDIHeVrIjGIEhYw84EDL2_25ymOolTeW2qkTgKShg59Njl36BuSvki54cVbVfHik5oQPbJDm5JNkT5LwLLf0tDBROxQm1JYlQBgxwrz2WHRLZVN77Mr1RpkGlsIs3HcYoHoT6iPWeJi0AK_-Ct6BIcam1ie5D__-cNv93osen3_5vR28axx_SGVWosmlNyNKoOHaWNXBGkOus822BAmC7VNIEkfO9p-w5zMJCPqvpCvoOAMNAcnfb7YZSWU5QiarxUy35SqcGkXdJTvSTb7NV94H7cPG9lBTms7CCkdkDItrz2WShKZK9K48bxw9436QmK8F_xwGCawBufRNa6iKhLlev67PlpYI1LOUPUURCqx7hj_fTsW5bB1dfeGdzCkgMHU0BgXC3EijBGJnzyix715-ItUvbRCLjP0urs0L4WHX4A6hJJvDHiP2w-c6qNnrS0fNcSaPRTefkBpOVg_gB4-Pu1jS-KHzS9OPqZA6OcYKp-4VQhMZvez2alUJhNiBAStVOxff_2D-V7b-vc2P8rZM81hkmBnzIMKZrvxjGoI_Ovyu9_6j3Orf7aJEu41dq_zo4vci_U4wBw12eQighqqIoQzMLFZpbfPEbItJukC6hIrK71ucFI_feXms023LB2NIl0Njx0BQNJREZZ9JZvUOzFeHRJ2uWwfSxbjcN4-BpPDcm1B46LLUP7KGR8xUSfF9OoyZm4CalLx7tWY8WuxWaveHUCfeIzJPhmvsUY2eH8coj1E11kSWv1V6vhbv_KHNi7x8flfot3b1COWr858JnB87i2qhzOSHBRCKw9zS99iQZrSR4FygyYgiL7hB5ycJnNupFjQlg6Bsi7MwhW6au5tyGNIPMyLO7Pr5J6uoFLEXF5LwEQP2um8yIQd_L031yhzi60yPyebH-b__R9F7S-eQgYAAA==.eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZXhwIjoxNzA1MDUxOTMzLCJhbGxvd0FjY2Vzc092ZXJQdWJsaWNJbnRlcm5ldCI6dHJ1ZX0=", // Keep as an empty string, null, or undefined.
			// 		tokenType: models.TokenType.Embed,
			// 	}}
			// />

			{/* <iframe
				width={1200}
				height={500}
				src="https://lookerstudio.google.com/embed/reporting/0e5d7f7f-07cb-4428-a3b9-6a912cbdb696/page/kIV1C"
				frameBorder="0"
				style={{ border: 0 }}
				allowFullScreen
				sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe> */}

			<iframe
				title="004 performance report"
				width="100%"
				height="500"
				src="https://app.powerbi.com/reportEmbed?reportId=1437d782-b35a-4923-98de-3144de655117&autoAuth=true&ctid=318482d0-852d-4888-b90a-8dfcd907b268"
				frameBorder="0"
				allowFullScreen></iframe>
		</Box>
	);
};

export default EcomReports;
