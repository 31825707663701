import { ClearRounded } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogTitle, Divider, IconButton, Stack, Typography } from "@mui/material";
import axios from "axios";
import RenderInputField from "components/EboStoreV4/CommonComponents/RenderInputFiled/RenderInputFiled";
import {
	autoUpdateStoreBasicDetailsData,
	autoUpdatestoreMainFieldsData,
	CHANNEL_OPTIONS,
	getColDefaultValue,
	storeBasicDetailsFields,
	storeMainFields,
	storeTypeDetails,
} from "components/EboStoreV4/data";
import { useEffect, useState } from "react";
import { GlobalState } from "context/GlobalContext";
import { arrType } from "data/globalData";

export const collectFloorNumber = (array, column) => {
	let data = array?.map((row) => {
		return {
			...row,
			name: row?.["name"],
			key: row?.["name"],
		};
	});
	console.log("collectFloorNumber", data);
	return data;
};
const CreateNewModal = ({ modal, setModal, fetchStores }) => {
	const { setSnackBar, snackBar, setSnackBarOpen, loginUser, setLoadingOpen } = GlobalState();
	const [distCustomData, setDistCustomData] = useState([]);

	const handleModalclose = () => {
		setModal(false);
	};

	useEffect(() => {
		getDistriCustomData();
	}, []);

	const getDistriCustomData = async () => {
		try {
			setLoadingOpen(true);
			const res = await axios.get(`/api/ebo_store_v4/get_distributer_customer`);
			console.log(res.data);
			setDistCustomData(res.data);
			setLoadingOpen(false);
		} catch (error) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Unable to get distributer and customer data",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setLoadingOpen(false);
		}
	};
	const handleFormSubmit = async (e) => {
		setLoadingOpen(true);
		e.preventDefault();
		Object.keys(newStoreBasicDetailsFields).forEach((key) => {
			if (newStoreBasicDetailsFields[key] === "SELECT") {
				newStoreBasicDetailsFields[key] = "";
			}
		});
		Object.keys(newStoreTypeDetails).forEach((key) => {
			if (newStoreTypeDetails[key] === "SELECT") {
				newStoreTypeDetails[key] = "";
			}
		});
		console.log("Store Details", newStoreBasicDetailsFields, newStoreTypeDetails);
		let body = {};
		body["newStoreBasicDetailsFields"] = newStoreBasicDetailsFields;
		body["newStoreTypeDetails"] = newStoreTypeDetails;
		await axios
			.post("/api/ebo_store_v4/create-new-store", body)
			.then((res) => {
				if (res.status === 201) {
					setSnackBarOpen(true);
					setSnackBar({
						...snackBar,
						message: `Store created successfully`,
						severity: "success",
					});
					setLoadingOpen(false);
					fetchStores();
					setModal(false);
				}
			})
			.catch((err) => {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: `Error creating Store`,
					severity: "error",
				});
				setLoadingOpen(false);
			});
	};
	// const [newStoreMainFields, setNewStoreMainFields] = useState(() => {
	// 	let obj = {};
	// 	storeMainFields?.forEach((attr) => {
	// 		obj[attr.key] = getColDefaultValue(attr);
	// 	});
	// 	return obj;
	// });

	const [newStoreBasicDetailsFields, setNewStoreBasicDetailsFields] = useState(() => {
		let obj = {};
		storeBasicDetailsFields?.forEach((attr) => {
			obj[attr.key] = getColDefaultValue(attr);
		});
		return obj;
	});
	const [newStoreTypeDetails, setNewStoreTypeDetails] = useState(() => {
		let obj = {};
		storeTypeDetails?.forEach((attr) => {
			obj[attr.key] = getColDefaultValue(attr);
		});
		return obj;
	});
	let distributerData = distCustomData?.filter((row) => row.type === "distributer");
	let customerData = distCustomData?.filter((row) => row.type === "customer");

	console.log("newStoreTypeDetails", newStoreTypeDetails);
	return (
		<Dialog maxWidth="md" fullWidth open={modal} onClose={handleModalclose} component="form" onSubmit={handleFormSubmit}>
			<Box p={2}>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Typography variant="h6">Create Store</Typography>
					<Box>
						<IconButton onClick={handleModalclose}>
							<ClearRounded />
						</IconButton>
					</Box>
				</Box>
				<Stack spacing={1} width="100%">
					{" "}
					{/* <Box display="flex" columnGap={"10px"} rowGap="10px" flexWrap="wrap" width="100%">
						{storeMainFields?.map((row) => {
							return (
								<RenderInputField
									column={row}
									data={newStoreMainFields}
									setData={setNewStoreMainFields}
									width="24%"
									selectOptObjName={"option_data"}
									dependencyColData={autoUpdatestoreMainFieldsData}
								/>
							);
						})}
					</Box>
					<Divider orientation="horizontal" /> */}
					<Box display="flex" flexWrap="wrap" columnGap={"10px"} rowGap="10px" width="100%">
						<Typography variant="button" width="100%">
							Store Type Details
						</Typography>
						{storeTypeDetails?.map((row) => {
							if (row.key === "code" && !["SELECT", ""].includes(newStoreTypeDetails["channel"])) {
								if (newStoreTypeDetails["channel"] !== "EBO") {
									row.constraints.DISPLAY = true;
								} else {
									row.constraints.DISPLAY = false;
								}
							} else if (row.key === "code") {
								row.constraints.DISPLAY = false;
							}
							if (row.key === "store_type") {
								if (newStoreTypeDetails["channel"] !== "EBO") {
									row.constraints.DISPLAY = false;
								} else {
									row.constraints.DISPLAY = true;
								}
							}
							return (
								<RenderInputField
									column={row}
									data={newStoreTypeDetails}
									setData={setNewStoreTypeDetails}
									width="24%"
									selectOptObjName={"option_data"}
									dependencyColData={autoUpdatestoreMainFieldsData}
								/>
							);
						})}
					</Box>
					<Divider orientation="horizontal" />
					<Box display="flex" flexWrap="wrap" columnGap={"10px"} rowGap="10px" width="100%">
						<Typography variant="button" width="100%">
							Basic Details
						</Typography>
						{storeBasicDetailsFields?.map((row) => {
							let option_data = row.option_data ? row.option_data : {};
							if (row.key === "distributer") {
								option_data = {
									type: arrType,
									list: collectFloorNumber(distributerData, "distributer"),
								};
							}
							if (row.key === "customer") {
								option_data = {
									type: arrType,
									list: collectFloorNumber(customerData, "customer"),
								};
							}
							if (
								(row.key === "customer" || row.key === "distributer") &&
								newStoreTypeDetails["channel"] === "EBO" &&
								!["SELECT", ""].includes(newStoreTypeDetails["store_type"])
							) {
								if (["FOCO", "FOFO"].includes(newStoreTypeDetails["store_type"])) {
									row.constraints.DISPLAY = true;
								} else {
									row.constraints.DISPLAY = false;
								}
							} else if (
								(row.key === "customer" || row.key === "distributer") &&
								newStoreTypeDetails["channel"] !== "EBO"
							) {
								row.constraints.DISPLAY = true;
							}
							// else if (row.key === "customer" || row.key === "distributer") {
							// 	row.constraints.DISPLAY = false;
							// }
							return (
								<RenderInputField
									column={{ ...row, option_data }}
									data={newStoreBasicDetailsFields}
									setData={setNewStoreBasicDetailsFields}
									width="24%"
									selectOptObjName={"option_data"}
									dependencyColData={autoUpdateStoreBasicDetailsData}
								/>
							);
						})}
					</Box>
				</Stack>

				<DialogActions sx={{ pt: 2 }}>
					<Button variant="outlined" sx={{ justifySelf: "center" }} onClick={handleModalclose}>
						Cancel
					</Button>
					<Button variant="contained" sx={{ justifySelf: "center" }} type="submit">
						Create
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
};

export default CreateNewModal;
