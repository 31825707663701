import React, { useState, useEffect, useRef } from "react";
import axios, { all } from "axios";
import "./businessPlanning.css";

import { BusinessPlanState } from "../../context/BusinessPlanningContext";
import { GlobalState } from "../../context/GlobalContext";
import BrandLevelBp from "./StoresTable/StoresTable";
import CreateBusinessPlan from "./CreateBusinessPlan/CreateBusinessPlan";
import CircularProgressWithLabel from "../ReusableComponents/CircularProgressWithLabel";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { grey } from "@mui/material/colors";
import { DataGrid } from "@mui/x-data-grid";
import Store from "./Store/Store";
import StoresTable from "./StoresTable/StoresTable";
import { Popper } from "@mui/material";
import { InLakhsDisplay } from "components/ReusableComponents/InLakhsDisplay";
import { AuthState } from "context/AuthContext";
import { custom } from "data/authData";

const bpTablecolumns = [
	{ field: "name", headerName: "BUSINESS PLAN", width: 130, headerAlign: "center", align: "center" },
	{
		field: "channel",
		headerName: "CHANNEL",
		headerAlign: "center",
		align: "center",
		width: 100,
		renderCell: (params) => {
			return <>{params.row.channel}</>;
		},
	},
	{ field: "total_count", headerName: "STORE COUNT", width: 110, headerAlign: "center", align: "center" },
	{ field: "total_qty", headerName: "TOTAL QTY", width: 100, headerAlign: "center", align: "center" },
	{
		field: "new_dis",
		headerName: "DISCOUNT",
		width: 100,
		headerAlign: "center",
		align: "center",
		renderCell: (params) => {
			// console.log(params);
			return <>{params.row.new_dis}%</>;
		},
	},
	// {
	// 	field: "new_st",
	// 	headerName: "AVG ST",
	// 	width: 100,
	// 	headerAlign: "center",
	// 	align: "center",
	// 	renderCell: (params) => {
	// 		// console.log(params);
	// 		return <>{params.row.new_st}%</>;
	// 	},
	// },
	{
		field: "total_opt",
		headerName: "NEW OPTIONS",
		width: 95,
		headerAlign: "center",

		renderCell: (params) => {
			// console.log(params);
			return <>{params.row?.total_opt}</>;
		},
	},
	// {
	// 	field: "new_opt",
	// 	headerName: "NEW OPTIONS",
	// 	width: 95,
	// 	headerAlign: "center",

	// 	renderCell: (params) => {
	// 		// console.log(params);
	// 		return <>{params.row?.new_opt}</>;
	// 	},
	// },
	// {
	// 	field: "new_opt",
	// 	headerName: "NEW OPTIONS",
	// 	width: 95,
	// 	headerAlign: "center",

	// 	renderCell: (params) => {
	// 		// console.log(params);
	// 		return <>{params.row?.new_opt}</>;
	// 	},
	// },
	{ field: "new_asp", headerName: "AVG SP", width: 100, headerAlign: "center", align: "center" },
	{
		field: "new_nsv",
		headerName: "TOTAL SP",
		width: 100,
		headerAlign: "center",
		align: "center",
		renderCell: ({ row }) => {
			return <InLakhsDisplay number={row.new_nsv} sx={{ fontWeight: "normal" }} />;
		},
	},
	{
		field: "status",
		align: "center",
		headerName: "Status",
		headerAlign: "center",
		align: "center",
		width: 100,
		renderCell: (params) => {
			const value = Math.round((+params.row.current_count / +params.row.current_count) * 100);
			return <CircularProgressWithLabel value={+value ? value : 0} />;
		},
	},

	{
		field: "is_submitted",
		headerName: "SUBMIT STATUS",
		width: 120,
		headerAlign: "center",
		align: "center",
		renderCell: (params) => {
			// console.log(params);
			return <>{params.row.is_submitted ? "Submitted" : "Pending"}</>;
		},
	},
];

function BusinessPlanning() {
	const [modalOpen, setModalOpen] = useState(false);
	const [planInfoModalOpen, setPlanInfoModalOpen] = useState(false);
	const [season, setSeason] = useState("select");
	const [year, setYear] = useState(0);
	const [bpLocal, setBpLocal] = useState([]);
	const { user } = AuthState();

	const { allBp, setAllBp, selectedBp, setSelectedBp, selectedStore, setSelectedStore } = BusinessPlanState();

	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const date = new Date();
	useEffect(() => {
		const fetch = async () => {
			try {
				await axios.get("/api/business_plan").then((res) => {
					setAllBp(res.data.business_plan);
					setBpLocal(res.data.business_plan);
				});
			} catch (error) {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "Network Error",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			}
		};
		fetch();
	}, []);

	useEffect(() => {
		clearSeasonFilter();
	}, [allBp]);
	// useEffect(() => {
	// 	if (selectedChannel) scrollToStores();
	// }, [selectedBp]);

	const clearSeasonFilter = () => {
		setSeason("select");
		setYear(0);
		allBp ? setBpLocal(allBp) : setAllBp([]);
	};
	const scrollToStores = () => {
		window.scrollTo({ top: 700, behavior: "smooth" });
	};

	const handlePlanSelect = (bp) => {
		scrollToStores();
		setSelectedBp(bp);
	};

	const handleFilterSeasonChange = (season) => {
		setSeason(season);
		if (season !== "select") {
			if (year !== 0) setBpLocal(allBp.filter((row) => row.season === season && row.year === year));
			else setBpLocal(allBp.filter((row) => row.season === season));
		} else {
			if (year !== 0) setBpLocal(allBp.filter((row) => row.year === year));
			else setBpLocal(allBp);
		}
	};
	const handleFilterYearChange = (year) => {
		setYear(year);
		if (year !== 0) {
			if (season !== "select") setBpLocal(allBp.filter((row) => row.season === season && row.year === year));
			else setBpLocal(allBp.filter((row) => row.year === year));
		} else {
			if (season !== "select") setBpLocal(allBp.filter((row) => row.season === season));
			else setBpLocal(allBp);
		}
	};

	// const modalStyle = {
	const modalStyle = {
		width: 250,
		bgcolor: "background.paper",
		boxShadow: 24,
		p: 2,
		borderRadius: 2,
	};
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const id = open ? "simple-popper" : undefined;

	return (
		<Stack spacing={4} maxWidth="1200px" margin="auto">
			{/* heading */}
			<Box display="flex" alignItems="center" pt={4} justifyContent="space-between">
				<Typography variant="h3">Business Planning</Typography>
				{user && user.role[custom.RSM.key] !== true && (
					<Button
						variant="contained"
						aria-describedby={id}
						sx={{ height: "50px" }}
						startIcon={<AddRoundedIcon />}
						onClick={(e) => {
							setModalOpen(true);
							setAnchorEl(anchorEl ? null : e.currentTarget);
						}}>
						New Plan
					</Button>
				)}
				<Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end">
					<Box sx={modalStyle}>
						<CreateBusinessPlan setModalOpen={setModalOpen} setAnchorEl={setAnchorEl} scrollToStores={scrollToStores} />
					</Box>
				</Popper>
			</Box>

			<Box width="100%">
				{/* filter box */}
				<Box display="flex">
					<Typography variant="h6" alignSelf="flex-end">
						All Plans
					</Typography>
					<Box
						marginLeft="auto"
						display="flex"
						maxWidth="410px"
						columnGap={1}
						alignItems="center"
						sx={{ background: "var(--grayBgColor)" }}
						p={1}
						borderRadius={2}>
						<Typography>Filter</Typography>
						<Select size="small" value={season} onChange={(e) => handleFilterSeasonChange(e.target.value)}>
							<MenuItem value={"select"}>Season</MenuItem>
							<MenuItem value={"AW"}>AW</MenuItem>
							<MenuItem value={"SS"}>SS</MenuItem>
						</Select>
						<Select
							size="small"
							value={year}
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							onChange={(e) => handleFilterYearChange(e.target.value)}>
							<MenuItem value={0}>Year</MenuItem>

							<MenuItem value={date.getFullYear()}>{date.getFullYear()}</MenuItem>

							{[1, 2, 3, 4].map((row) => (
								<MenuItem key={row} value={date.getFullYear() + row}>
									{date.getFullYear() + row}
								</MenuItem>
							))}
						</Select>
						<Button variant="contained" onClick={clearSeasonFilter}>
							Clear
						</Button>
					</Box>
				</Box>

				{/* Bp Table */}
				{/* <Stack pt={1} height={300} sx={{ overflowY: "scroll", overflowX: "hidden" }}>
					{bpLocal && bpLocal.length > 0 ? (
						bpLocal.map((row) => (
							<Box
								key={row.id}
								display="flex"
								width="100%"
								sx={{
									border: "1px solid lightgray",
									background: selectedBp ? (row.id === selectedBp.id ? grey[100] : "none") : "none",
									"&:hover": {
										background: grey[100],
									},
								}}
								p="2px 5px"
								onClick={(e) => handleBpTableChannelClick(e, row)}
								borderRadius={2}
								justifyContent="space-between">
								<Box display="flex" alignItems="center" sx={{ cursor: "pointer" }}>
									<Typography variant="subtitle1" pr={2}>
										{row.name}
									</Typography>
									<CircularProgressWithLabel
										value={Math.floor((row.current_count / row.total_count) * 100)}
										size={35}
									/>
								</Box>
								<Box
									width="60%"
									display="flex"
									justifyContent="space-between"
									sx={{ cursor: "pointer" }}>
									{row.bPlanChannels &&
										row.bPlanChannels.map((chRow) => {
											return (
												<Box display="flex" alignItems="center" columnGap={2}>
													{chRow.channel}
													<CircularProgressWithLabel
														value={Math.floor(
															(chRow.current_count / chRow.total_count) * 100
														)}
													/>
												</Box>
											);
										})}
								</Box>
							</Box>
						))
					) : (
						<Box
							display="flex"
							flexDirection="column"
							width="100%"
							sx={{ border: "1px solid lightgray" }}
							p={1}
							borderRadius={2}
							justifyContent="center"
							alignItems="center">
							<Typography>No Rows</Typography>
							<Button
								variant="contained"
								sx={{ height: "50px" }}
								startIcon={<AddRoundedIcon />}
								onClick={() => setModalOpen(true)}>
								New Plan
							</Button>
						</Box>
					)}
				</Stack> */}

				<Stack width="90%" height={400} pt={3}>
					<DataGrid
						rows={bpLocal}
						columns={bpTablecolumns}
						sx={{
							minHeight: 300,
							maxHeight: 350,
							"& .MuiDataGrid-row": {
								borderBottom: "1px solid lightgray",
								cursor: "pointer",
							},
						}}
						rowHeight={40}
						showCellVerticalBorder
						onRowClick={(params, e) => handlePlanSelect(params.row)}
						initialState={{
							pagination: {
								paginationModel: { pageSize: 20 },
							},
						}}
						disableRowSelectionOnClick
						pageSizeOptions={[20, 50]}
					/>
				</Stack>
			</Box>

			<div>{selectedBp ? <StoresTable key={selectedBp} /> : <></>}</div>
			{/* create bp modal */}
			{/* <Modal
				sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
				open={modalOpen}
				onClose={() => setModalOpen(false)}>
				<Box sx={modalStyle}>
					<CreateBusinessPlan setModalOpen={setModalOpen} scrollToStores={scrollToStores} />
				</Box>
			</Modal> */}
			{/* Bp Info Modal */}
			<Modal
				sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
				open={planInfoModalOpen}
				onClose={() => setPlanInfoModalOpen(false)}>
				<Stack sx={modalStyle}>
					<Box>
						<Box display="flex">
							<Typography variant="h6" pr={2}>
								AW-23 Plan
							</Typography>
							<CircularProgressWithLabel value={20} />
						</Box>
						<Box display="flex">
							<Typography variant="subtitle1" pr={2}>
								total qty:0
							</Typography>
							<Typography variant="subtitle1" pr={2}>
								total mrp:0
							</Typography>
						</Box>
					</Box>
					<Box display="flex" alignItems="center" pt={2}>
						<Typography variant="subtitle2" pr={2} color="text.secondary">
							EBO
						</Typography>
						<Typography variant="subtitle1" pr={2}>
							total qty:0
						</Typography>
						<Typography variant="subtitle1" pr={2}>
							total mrp:0
						</Typography>
						<CircularProgressWithLabel value={20} />
					</Box>
					<Box display="flex" alignItems="center" pt={2}>
						<Typography variant="subtitle2" pr={2} color="text.secondary">
							MBO
						</Typography>
						<Typography variant="subtitle1" pr={2}>
							total qty:0
						</Typography>
						<Typography variant="subtitle1" pr={2}>
							total mrp:0
						</Typography>
						<CircularProgressWithLabel value={20} />
					</Box>
					<Box display="flex" alignItems="center" pt={2}>
						<Typography variant="subtitle2" pr={2} color="text.secondary">
							LFS
						</Typography>
						<Typography variant="subtitle1" pr={2}>
							total qty:0
						</Typography>
						<Typography variant="subtitle1" pr={2}>
							total mrp:0
						</Typography>
						<CircularProgressWithLabel value={20} />
					</Box>
				</Stack>
			</Modal>
		</Stack>
	);
}

export default BusinessPlanning;
