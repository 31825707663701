import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import { grey } from "@mui/material/colors";
import NumberTextField from "components/ReusableComponents/NumberTextField";
import { datatypes } from "data/globalData";

const CustomTextField = ({ data, setData, column, width, required, type, sx, disabled, onChange, disableLabel, variant }) => {
	return (
		<Stack sx={{ width }} spacing={"3px"} height={"70px"}>
			{!disableLabel && (
				<InputLabel sx={{ color: grey[600] }}>
					{column?.name}
					{required && <span style={{ color: "red" }}>*</span>}
				</InputLabel>
			)}
			{column?.data_type === datatypes.NUMBER.key ? (
				<NumberTextField
					size="small"
					fullWidth
					variant={variant ? variant : "standard"}
					sx={sx}
					disableHelperText
					disabled={disabled}
					onChange={onChange}
					value={data && data[column.key] ? data[column.key] : 0}
					placeholder={column?.placeholder ? column?.placeholder : ""}
				/>
			) : (
				<TextField
					variant={variant ? variant : "standard"}
					sx={sx}
					size="small"
					type={datatypes[column?.data_type] ? datatypes[column?.data_type].htmlVar : "text"}
					value={data && data[column.key] ? data[column.key] : ""}
					onChange={onChange}
					disabled={disabled}
					placeholder={column?.placeholder ? column?.placeholder : `Enter ${column?.key}`}
					required={required ? required : false}
					fullWidth
				/>
			)}
		</Stack>
	);
};

export default CustomTextField;
