import React, { useState, useEffect } from "react";
import {
	Box,
	Card,
	CardContent,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Grid,
	Stack,
	TextField,
	Button,
	FormControl,
} from "@mui/material";
import { GlobalState } from "context/GlobalContext";
import axios from "axios";
import { InLakhsDisplay } from "components/ReusableComponents/InLakhsDisplay";

function Summary() {
	const [summaryData, setSummaryData] = useState({});
	const [topStores, setTopStores] = useState([]);
	const [bottomStores, setBottomStores] = useState([]);
	const [open, setOpen] = useState(false);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const handleClick = (value) => {
		setOpen(true);
		navigator.clipboard.writeText(value);
		setSnackBarOpen(true);
		setSnackBar({
			...snackBar,
			message: "Copied to clipboard",
			severity: "success",
			anchorOrigin: { horizontal: "center", vertical: "top" },
		});
	};

	const fetch = async (e) => {
		try {
			e?.preventDefault();
			const [summaryResponse, topFiveStores] = await Promise.all([
				axios.get(`/api/pos_reco/get-pos-summary?startDate=${startDate}&endDate=${endDate}`),
				axios.get(`/api/pos_reco/get-top-stores?startDate=${startDate}&endDate=${endDate}`),
			]);
			setSummaryData(summaryResponse.data[0]);
			setTopStores(topFiveStores.data.slice(0, 5));
			setBottomStores(topFiveStores.data.slice(-5).reverse());
		} catch (error) {
			console.log("ERROR IN FETCH", error);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Error fetching summary",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
		}
	};

	useEffect(() => {
		fetch();
	}, []);

	const cardData = Object.entries(summaryData)
		.filter(([key, value]) => key !== "MOP" && value !== null)
		.map(([key, value]) => ({
			label: key,
			value: value,
		}));

	return (
		<Box sx={{ flexGrow: 1 }}>
			<Stack direction={"row"} justifyContent={"space-between"}>
				<Typography variant="h4" sx={{ mb: 2 }}>
					Sale Summary
				</Typography>
				<Box display="flex" columnGap={2} component={"form"} onSubmit={fetch}>
					<Stack spacing={1}>
						<TextField
							label="Start Date"
							size="small"
							type="date"
							InputLabelProps={{ shrink: true }}
							value={startDate}
							onChange={(e) => setStartDate(e.target.value)}
							required
							placeholder="compliance certificate issue date"
						/>
					</Stack>
					<Stack spacing={1}>
						<FormControl>
							<TextField
								label="End Date"
								size="small"
								type="date"
								value={endDate}
								InputLabelProps={{ shrink: true }}
								onChange={(e) => setEndDate(e.target.value)}
								required
							/>
						</FormControl>
					</Stack>
					<Box>
						<Button variant="contained" type="submit" size="sm">
							Submit
						</Button>
					</Box>
				</Box>
			</Stack>

			<Grid container spacing={3} sx={{ mb: 4 }}>
				{cardData.map((card, index) => (
					<Grid item xs={12} sm={3} key={index}>
						<Card>
							<CardContent>
								<Typography variant="h6" component="div">
									{card.label}
								</Typography>
								<Typography variant="h4" component="div" onClick={() => handleClick(card.value)}>
									<InLakhsDisplay number={card.value} />
								</Typography>
							</CardContent>
						</Card>
					</Grid>
				))}
			</Grid>

			{/* Table */}
			<Stack direction="row" justifyContent={"space-between"}>
				<Stack width={"47%"}>
					<Typography variant="h4" sx={{ mb: 2 }}>
						Top 5 Stores By Sales
					</Typography>
					<TableContainer component={Paper}>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>Store</TableCell>
									<TableCell>Sale</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{topStores.map((row) => (
									<TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
										<TableCell component="th" scope="row">
											{row.NAME}
										</TableCell>
										<TableCell>{row.TotalSales}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Stack>
				<Stack width={"47%"}>
					<Typography variant="h4" sx={{ mb: 2 }}>
						Bottom 5 Stores By Sales
					</Typography>
					<TableContainer component={Paper}>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>Store</TableCell>
									<TableCell>Sale</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{bottomStores.map((row) => (
									<TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
										<TableCell component="th" scope="row">
											{row.NAME}
										</TableCell>
										<TableCell>{row.TotalSales}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Stack>
			</Stack>
		</Box>
	);
}

export default Summary;
