import {
	Box,
	Breadcrumbs,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogTitle,
	IconButton,
	InputAdornment,
	Modal,
	Popover,
	Select,
	Skeleton,
	Stack,
	Tab,
	Table,
	Tabs,
	TextField,
	Typography,
} from "@mui/material";

import { grey, red, green } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { divisionData } from "../../../data/businessPlanningData";
import { BusinessPlanState } from "../../../context/BusinessPlanningContext";
import axios from "axios";
import { InLakhsDisplay } from "../../ReusableComponents/InLakhsDisplay";
import { roundDecimalValueUptoTwo } from "../../../utils/roundDecimalValue";
import { AllMrpData } from "../../../data/mrpData";
import CategoryTable from "./CategoryTable/CategoryTable";
import { calculateStoreAge } from "utils/calculateStoreAge";
import { getDatesofSeason } from "utils/getDatesofSeason";
import { calculateAsp, calculateDiscount, calculateRos, calculateST } from "utils/calculateStoreData";
import { GlobalState } from "context/GlobalContext";
import GrowthPercentage from "components/ReusableComponents/GrowthPercentage";

import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import EditManualProjectionModal from "./EditManualProjectionModal/EditManualProjectionModal";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { AuthState } from "context/AuthContext";
import { useNavigate } from "react-router-dom";
import { custom, rootProtectedPath, services } from "data/authData";
import NumberTextField from "components/ReusableComponents/NumberTextField";
import { KeyboardBackspaceRounded } from "@mui/icons-material";

const textInfo = {
	fontSize: 14,
	color: grey[600],
};
const mrpBox = {
	width: 120,
	padding: "5px 0px",
	height: 50,
};
const tableBox = {
	width: "130px",
	padding: "6px 2px",
	...textInfo,
	textAlign: "center",
	fontSize: 14,
	display: "flex",
	justifyContent: "center",
	borderRight: `1px solid ${grey[200]}`,
	"&:last-child": {
		borderRight: "none",
	},
	// "&:first-child": {
	// 	width: "160px",
	// 	fontWeight: "bold",
	// },
};
const loadingSkeleton = {
	width: "80px",
	height: 30,
};
const storeInfoBox = {
	width: "100%",
	display: "flex",
	columnGap: 4,
};
const storeInfoColumn = {
	width: "45%",
	...textInfo,
};

const SSMonths = [
	{
		name: "Feb",
		key: 2,
	},
	{
		name: "Mar",
		key: 3,
	},
	{
		name: "Apr",
		key: 4,
	},
	{
		name: "May",
		key: 5,
	},
	{
		name: "Jun",
		key: 6,
	},
	{
		name: "Jul",
		key: 7,
	},
	{
		name: "Aug",
		key: 8,
	},
];
const AWMonths = [
	{
		name: "Aug",
		key: 8,
	},
	{
		name: "Sep",
		key: 9,
	},
	{
		name: "Oct",
		key: 10,
	},
	{
		name: "Nov",
		key: 11,
	},
	{
		name: "Dec",
		key: 12,
	},
	{
		name: "Jan",
		key: 1,
	},
	{
		name: "Feb",
		key: 2,
	},
];
const TopSellingMrpTable = ({ division, bpMonths, storeCode }) => {
	const startDate = "2023-02-14";
	const endDate = "2024-01-16";
	const [mrps, setMrps] = useState();
	const [loading, setLoading] = useState();
	const [error, setError] = useState("");
	useEffect(() => {
		const fetch = async () => {
			setMrps();
			setLoading(true);
			await axios
				.get(
					`/api/query_run/top_mrp_by_store_division?&startDate=${startDate}&endDate=${endDate}&storeCode=${storeCode}&division=${division}`
				)
				.then((res) => {
					setMrps(res.data);
					setLoading(false);
				})
				.catch((err) => {
					setLoading(false);
					setError("Unable to load Mrp");
				});
		};
		fetch();
	}, [division]);

	const head = {
		// width: "100px",
		textAlign: "center",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
		// background: grey[100],
		// boxShadow: `1px 1px 5px ${grey[300]}`,
		// border: `1px solid ${grey[300]}`,
		// borderBottom: "1px solid #f2f2f2",
	};
	const monthRow = {
		fontSize: "12px",
		height: "20px",
		textTransform: "uppercase",
		// borderBottom: "1px solid #f2f2f2",
	};
	const mrpRow = {
		fontSize: "16px",
		height: "25px",
	};
	const effRow = {
		fontSize: "13px",
		display: "flex",
		columnGap: "10px",
		alignItems: "baseline",
	};
	const aspRow = {
		fontSize: "16px",
		height: "25px",
	};
	const tableBox = {
		width: "120px",
		borderRight: "1px solid #f2f2f2",
		"&:last-child": {
			borderRight: "none",
		},
		"&:first-child": {
			width: "100px",
		},
	};

	return (
		<Box>
			{/* <Typography padding="10px 0px">{division}</Typography> */}

			{/* Top Selling Mrp */}
			{mrps ? (
				<Stack border={`1px solid ${grey[300]}`}>
					<Box
						width="100%"
						display="flex"
						// justifyContent="space-between"
						alignItems="center"
						p={1}

						// borderBottom={`1px solid ${grey[300]}`}
					>
						<Stack spacing={1} height="100%" sx={tableBox}>
							<Typography sx={{ ...head, ...monthRow }}>Month</Typography>
							<Typography sx={{ ...head, ...mrpRow }}>MRP</Typography>
							<Typography sx={{ ...head, ...effRow }}>CONTRI</Typography>
							<Typography sx={{ ...head, ...mrpRow }}>QTY</Typography>
							<Typography sx={{ ...head, ...aspRow }}>ASP</Typography>
						</Stack>
						{mrps.map((row) => {
							const mrpData = row;
							return (
								<Stack p={2} sx={tableBox} alignItems="center" key={row.key} spacing={1}>
									<Typography sx={{ ...head, ...monthRow }} textAlign="center">
										{bpMonths.filter((row) => row.key === mrpData.MONTH)[0].name}
									</Typography>
									<Typography sx={{ ...head, ...mrpRow }}>{mrpData.MRP}</Typography>
									<Box sx={{ ...head, ...effRow }}>
										<Typography fontSize={14} color="#800000" fontWeight="bold">
											{mrpData.CONTRIBUTION}
										</Typography>
										<Typography fontSize={12} fontWeight="bold">
											{mrpData.GROWTH_PERCENTAGE >= 0 ? (
												<span style={{ color: green["A700"] }}>{mrpData.GROWTH_PERCENTAGE} ↑</span>
											) : (
												<span style={{ color: red["A700"] }}>{Math.abs(mrpData.GROWTH_PERCENTAGE)} ↓</span>
											)}
										</Typography>
									</Box>
									<Typography sx={{ ...head, ...mrpRow }}>{mrpData.TOTAL_QUANTITY}</Typography>
									<Typography sx={{ ...head, ...aspRow }}>
										{Math.round(mrpData.VALUE / mrpData.TOTAL_QUANTITY)}
									</Typography>
								</Stack>
							);
						})}
					</Box>
				</Stack>
			) : loading ? (
				<Skeleton variant="rounded" sx={{ bgcolor: "grey.50" }} animation="wave" width="100%" height={200} />
			) : error !== "" ? (
				<Typography>{error}</Typography>
			) : (
				<></>
			)}
		</Box>
	);
};
const AllSellingMrpTable = ({ division, bpMonths, storeCode }) => {
	const startDate = "2023-02-14";
	const endDate = "2024-01-16";
	const [mrps, setMrps] = useState([1, 2, 3, 5, 6, 7, 8, 9, 12, 23, 44, 12, 4, 3, 4, 3, 4]);
	const [loading, setLoading] = useState();
	const [error, setError] = useState("");
	// useEffect(() => {
	// 	const fetch = async () => {
	// 		setMrps();
	// 		setLoading(true);
	// 		await axios
	// 			.get(
	// 				`/api/query_run/top_mrp_by_store_division?&startDate=${startDate}&endDate=${endDate}&storeCode=${storeCode}&division=${division}`
	// 			)
	// 			.then((res) => {
	// 				setMrps(res.data);
	// 				setLoading(false);
	// 			})
	// 			.catch((err) => {
	// 				setLoading(false);
	// 				setError("Unable to load Mrp");
	// 			});
	// 	};
	// 	fetch();
	// }, [division]);

	const text = {
		width: "150px",
		textAlign: "center",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		fontSize: "15px",
		height: "25px",
		p: "4px 15px",
		"&:first-child": {
			p: "8px 15px",
		},
	};
	const aspRow = {};
	const tableBox = {
		// width: "180px",
		borderRight: "1px solid #f2f2f2",
		"&:last-child": {
			borderRight: "none",
		},
	};
	const mrpHead = {
		background: grey[200],
		boxShadow: `1px 1px 5px ${grey[300]}`,
		borderBottom: `1px solid ${grey[300]}`,
	};

	return (
		<Box>
			{/* <Typography padding="10px 0px">{division}</Typography> */}

			{/* Top Selling Mrp */}
			{mrps ? (
				<Stack border={`1px solid ${grey[300]}`}>
					<Box
						width="100%"
						display="flex"
						// alignItems="center"
						position="relative"
						// p={1}

						// borderBottom={`1px solid ${grey[300]}`}
					>
						<Stack spacing={1} sx={{ ...tableBox, boxShadow: `1px 1px 5px ${grey[300]}` }}>
							<Typography sx={{ ...text, ...mrpHead }}>MRP</Typography>

							<Typography sx={text}>Qty Received</Typography>
							<Typography sx={text}>Option Received</Typography>
							<Typography sx={text}>Qty Sold</Typography>
							<Typography sx={text}>Sale Through</Typography>
							<Typography sx={text}>Realized Value</Typography>
							<Typography sx={text}>Discount Percent</Typography>
							<Box pt={1}>
								<Typography sx={text} pt={1}>
									Planned Disc %
								</Typography>
							</Box>

							<Box pt={1}>
								<Typography sx={text}>Planned Qty</Typography>
							</Box>
						</Stack>
						<Box
							width="100%"
							display="flex"
							// alignItems="center"
							overflow="scroll"
							// p={1}

							// borderBottom={`1px solid ${grey[300]}`}
						>
							{AllMrpData.map((row) => {
								// const mrpData = row;
								return (
									<Stack sx={tableBox} alignItems="center" key={row} spacing={1}>
										<Typography sx={{ ...text, ...mrpHead }}>{row.MRP}</Typography>

										<Box sx={{ ...text, ...aspRow }}>
											<Typography>{row.QTY_RECEIVED}</Typography>
											<span style={{ color: "#800000" }}>
												{roundDecimalValueUptoTwo(row.QTY_RECEIVED_CONTRIBUTION * 100)}%
											</span>
										</Box>
										<Box sx={{ ...text, ...aspRow }}>
											<Typography sx={{ fontSize: 16, fontWeight: "bold" }}>{row.OPTION_RECEIVED}</Typography>
											<span style={{ color: "#800000" }}>
												{roundDecimalValueUptoTwo(row.OPTION_RECEIVED_CONTRIBUTION * 100)}%
											</span>
										</Box>
										<Box sx={{ ...text, ...aspRow }}>
											<Typography>{row.QTY_SOLD}</Typography>
											<span style={{ color: "#800000" }}>
												{roundDecimalValueUptoTwo(row.QTY_SOLD_CONTRIBUTION * 100)}%
											</span>
										</Box>
										<Typography sx={{ ...text, ...aspRow, fontSize: 18, fontWeight: "bold" }}>
											{roundDecimalValueUptoTwo(row.ST_PERCENT * 100)}%
										</Typography>
										<Box sx={{ ...text, ...aspRow }}>
											<Typography>{roundDecimalValueUptoTwo(row.REALISED_VALUE)}</Typography>
											<span style={{ color: "#800000" }}>
												{roundDecimalValueUptoTwo(row.REALISED_VALUE_CONTRIBUTION * 100)}%
											</span>
										</Box>
										<Typography sx={{ ...text, fontSize: 16, fontWeight: "bold" }}>
											{roundDecimalValueUptoTwo(row.DISCOUNT_PERCENT * 100)}%{" "}
										</Typography>
										{/* discount */}
										{row.MRP !== "Total" ? (
											<TextField sx={{ ...text }} size="small" variant="outlined" />
										) : (
											<Box pt={1}>
												<Typography sx={text}>0%</Typography>
											</Box>
										)}

										<Box pt={1} display="flex" justifyContent="space-around" alignItems="center" width="100%">
											{row.MRP !== "Total" ? (
												<Box display="flex" justifyContent="space-between" width="90%" alignItems="center">
													<TextField
														sx={{ ...text, width: "100px", height: "40px" }}
														size="small"
														variant="outlined"
														alignItems="center"
													/>
													<span style={{ color: "#800000", fontSize: 14 }}>0%</span>
												</Box>
											) : (
												<Box>
													<Typography sx={text}>0</Typography>
												</Box>
											)}
										</Box>
									</Stack>
								);
							})}
						</Box>
					</Box>
				</Stack>
			) : loading ? (
				<Skeleton variant="rounded" sx={{ bgcolor: "grey.50" }} animation="wave" width="100%" height={200} />
			) : error !== "" ? (
				<Typography>{error}</Typography>
			) : (
				<></>
			)}
		</Box>
	);
};

const HistoryLoadingSkeleton = ({ sx, variant }) => {
	const style = sx ? sx : {};
	return (
		<Box
			sx={{
				borderRight: `1px solid ${grey[300]}`,
				"&:last-child": {
					borderRight: "none",
				},
			}}>
			<Skeleton sx={sx ? sx : {}} variant={variant ? variant : "rounded"} />
		</Box>
	);
};

const GrowthPercent = ({ number, GrowthPercent, inLakhs, percent }) => {
	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
			}}>
			<Box pr={1}>
				{!inLakhs ? (
					<Typography sx={{ fontSize: 14, fontWeight: "normal" }}>
						{number}
						{percent && "%"}
					</Typography>
				) : (
					<InLakhsDisplay number={number} sx={{ fontSize: 16, fontWeight: "normal" }} />
				)}
			</Box>
			<GrowthPercentage number={GrowthPercent} />
		</Box>
	);
};

const SeasonInfoGrowthRow = ({ row }) => {
	const [popover, setPopover] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	return (
		<Box display="flex">
			<Box sx={{ ...tableBox, width: "160px" }}>
				{row ? (
					<Box display="flex" columnGap={1} alignItems="center">
						<IconButton size="small" aria-describedby={id} onClick={handleClick}>
							<InfoRoundedIcon sx={{ transform: "scale(0.7)" }} />
						</IconButton>
						<Popover
							open={open}
							anchorEl={anchorEl}
							onClose={handlePopoverClose}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
							sx={{ boxShadow: "none" }}
							disableRestoreFocus>
							{" "}
							<Typography fontWeight={{ ...textInfo, fontWeight: "bold" }} p={1}>
								Derived VS New
							</Typography>
						</Popover>
						<Typography fontWeight={{ ...textInfo, fontWeight: "bold" }}>GROWTH</Typography>
					</Box>
				) : (
					<HistoryLoadingSkeleton sx={{ ...loadingSkeleton, width: "115px" }} variant="rounded" />
				)}
			</Box>
			<Box sx={tableBox}>
				{row ? <GrowthPercentage number={row.revenue} /> : <HistoryLoadingSkeleton sx={loadingSkeleton} variant="rounded" />}
			</Box>
			<Box sx={tableBox}>
				{row ? <GrowthPercentage number={row.asp} /> : <HistoryLoadingSkeleton sx={loadingSkeleton} variant="rounded" />}
			</Box>

			<Box sx={tableBox}>
				{row ? <GrowthPercentage number={row.dis} /> : <HistoryLoadingSkeleton sx={loadingSkeleton} variant="rounded" />}
			</Box>

			<Box sx={tableBox}>
				{row ? <GrowthPercentage number={row.ros} /> : <HistoryLoadingSkeleton sx={loadingSkeleton} variant="rounded" />}
			</Box>
			{/* <Box sx={tableBox}>
				{row ? <GrowthPercentage number={row.st} /> : <HistoryLoadingSkeleton sx={loadingSkeleton} variant="rounded" />}{" "}
			</Box> */}

			{/* <Box sx={tableBox}>
				{row ? <GrowthPercentage number={row.rtv} /> : <HistoryLoadingSkeleton sx={loadingSkeleton} variant="rounded" />}
			</Box> */}
		</Box>
	);
};
const SeasonInfoTableRow = ({ row, displayPercent, LTLData: totalLTLData }) => {
	const [percent, setPercent] = useState();
	useEffect(() => {
		if (row) {
			let obj = {};
			if (totalLTLData)
				obj = {
					revenue: row ? ((row.revenue - totalLTLData.revenue) / totalLTLData.revenue) * 100 : 0,
					asp: row ? ((row.asp - totalLTLData.asp) / totalLTLData.asp) * 100 : 0,
					dis: row ? ((row.dis - totalLTLData.dis) / totalLTLData.dis) * 100 : 0,
					ros: row ? ((row.ros - totalLTLData.ros) / totalLTLData.ros) * 100 : 0,
					st: row ? ((row.st - totalLTLData.st) / totalLTLData.st) * 100 : 0,
					rtv: row ? ((row.rtv - totalLTLData.rtv) / totalLTLData.rtv) * 100 : 0,
				};
			setPercent(obj);
		}
	}, [row, totalLTLData]);
	return (
		<Box display="flex">
			<Box sx={{ ...tableBox, width: "160px" }}>
				{row ? (
					<Typography fontWeight={{ ...textInfo, fontWeight: "bold" }}>{row.range}</Typography>
				) : (
					<HistoryLoadingSkeleton sx={loadingSkeleton} variant="rounded" />
				)}
			</Box>
			<Box sx={tableBox}>
				{row ? (
					displayPercent && percent ? (
						<GrowthPercent number={row.revenue} GrowthPercent={percent.revenue} inLakhs={true} />
					) : (
						<InLakhsDisplay number={row.revenue} sx={{ fontSize: 16, fontWeight: "normal" }} />
					)
				) : (
					<HistoryLoadingSkeleton sx={loadingSkeleton} variant="rounded" />
				)}
			</Box>
			<Box sx={tableBox}>
				{row ? (
					displayPercent && percent ? (
						<GrowthPercent number={row.asp} GrowthPercent={percent.asp} />
					) : (
						<span style={textInfo}>{row.asp}</span>
					)
				) : (
					<HistoryLoadingSkeleton sx={loadingSkeleton} variant="rounded" />
				)}
			</Box>
			<Box sx={tableBox}>
				{row ? (
					displayPercent && percent ? (
						<GrowthPercent number={row.dis} GrowthPercent={percent.dis} percent={true} />
					) : (
						<span style={textInfo}>{row.dis}</span>
					)
				) : (
					<HistoryLoadingSkeleton sx={loadingSkeleton} variant="rounded" />
				)}
			</Box>
			<Box sx={tableBox}>
				{row ? (
					displayPercent && percent ? (
						<GrowthPercent number={row.ros} GrowthPercent={percent.ros} />
					) : (
						<span style={textInfo}>{row.ros}</span>
					)
				) : (
					<HistoryLoadingSkeleton sx={loadingSkeleton} variant="rounded" />
				)}
			</Box>
			{/* <Box sx={tableBox}>
				{row ? (
					displayPercent && percent ? (
						<GrowthPercent number={row.st} GrowthPercent={percent.st} percent={true} />
					) : (
						<span style={textInfo}>{row.st}%</span>
					)
				) : (
					<HistoryLoadingSkeleton sx={loadingSkeleton} variant="rounded" />
				)}
			</Box> */}
			{/* <Box sx={tableBox}>
				{row ? (
					displayPercent && percent ? (
						<GrowthPercent number={row.rtv} GrowthPercent={percent.rtv} percent={true} />
					) : (
						<span style={textInfo}>{row.rtv}%</span>
					)
				) : (
					<HistoryLoadingSkeleton sx={loadingSkeleton} variant="rounded" />
				)}
			</Box>{" "} */}
		</Box>
	);
};
const Store = () => {
	const { selectedBp, selectedStore, setSelectedStore } = BusinessPlanState();
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();
	const { user } = AuthState();
	const navigate = useNavigate();
	const storeCategories = selectedStore ? selectedStore.storeCategories : [];
	// const bpMonths = useState(selectedBp.season === "AW" ? AWMonths : SSMonths);
	const [tab, setTab] = useState("APPARELS");
	const [bpMonths, setBpMonths] = useState([]);
	const [newTarget, setNewTarget] = useState();
	const [derivedTarget, setDerivedTarget] = useState();
	const [userType, setUserType] = useState(custom.RSM.key);
	const [monthWiseProjection, setMonthWiseProjection] = useState();
	const [LTLDataMonthWise, setLTLDataMonthWise] = useState();
	const [totalLTLData, setTotalLtlData] = useState();
	const [totalLast6MData, setTotalLast6MData] = useState();
	const [growthRowData, setGrowthRowData] = useState();
	const [percentage, setPercentage] = useState({
		"NEW TARGET": {
			revenue: 0,
			asp: 0,
			dis: 0,
			ros: 0,
		},
		"DERIVED TARGET": {
			revenue: 0,
			asp: 0,
			dis: 0,
			ros: 0,
		},
		GROWTH: {
			revenue: 0,
			asp: 0,
			dis: 0,
			ros: 0,
		},
	});
	const [divisionWiseContri, setDivisionWiseContri] = useState();
	const [growthToPrvSeason, setGrowthToPrvSeason] = useState();
	const [ltlSeasonST, setLtlSeasonST] = useState();
	const [editModal, setEditModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const { startDate, endDate } = getDatesofSeason(selectedBp && selectedBp.season, selectedBp && selectedBp.year);

	const [targetSubmitLoading, setTargetSubmitLoading] = useState(false);

	const [repeatCustomers, setRepeatCustomers] = useState(0);

	// calculate last 6 m and LTL Data
	useEffect(() => {
		const fetch = async () => {
			// setSeason months
			setBpMonths(selectedBp && selectedBp.season === "AW" ? AWMonths : SSMonths);
			// console.log(selectedStore);
			// calculate division wise calculation
			if (selectedStore && selectedStore.storeCategories) {
				const contri = storeCategories.reduce(
					(total, row) => {
						if (row.division === "APPARELS") total.apparelTotal += row.ltl_qty;
						else if (row.division === "ADDONS") total.addonTotal += row.ltl_qty;
						else if (row.division === "FOOT WEAR") total.footwearTotal += row.ltl_qty;

						return total;
					},
					{ apparelTotal: 0, addonTotal: 0, footwearTotal: 0 }
				);
				const totalQty = contri.apparelTotal + contri.addonTotal + contri.footwearTotal;
				setDivisionWiseContri({
					APPARELS: roundDecimalValueUptoTwo((contri.apparelTotal / totalQty) * 100),
					ADDONS: roundDecimalValueUptoTwo((contri.addonTotal / totalQty) * 100),
					FOOTWEAR: roundDecimalValueUptoTwo((contri.footwearTotal / totalQty) * 100),
				});
			}
			if (selectedStore && selectedStore.storeSeasonWiseData) {
				if (selectedStore.stage !== custom.RSM.key) {
					setDerivedTarget(
						selectedStore.storeSeasonWiseData.filter(
							(row) => row.is_new_target === false && row.stage === custom.NSM.key
						)[0]
					);
					setNewTarget(
						selectedStore.storeSeasonWiseData.filter(
							(row) => row.is_new_target === true && row.stage === custom.NSM.key
						)[0]
					);
				} else {
					setDerivedTarget(
						selectedStore.storeSeasonWiseData.filter(
							(row) => row.is_new_target === false && row.stage === custom.RSM.key
						)[0]
					);
					setNewTarget(
						selectedStore.storeSeasonWiseData.filter(
							(row) => row.is_new_target === true && row.stage === custom.RSM.key
						)[0]
					);
				}
			}
			if (selectedStore && selectedStore.storeProjection) {
				if (selectedStore.stage !== custom.RSM.key) {
					setMonthWiseProjection(selectedStore.storeProjection.filter((row) => row.stage === custom.NSM.key)[0]);
				} else {
					setMonthWiseProjection(selectedStore.storeProjection.filter((row) => row.stage === custom.RSM.key)[0]);
				}
			}
			// LTL Data
			try {
				const LTLDataMonthWise = new Promise(async (resolve, reject) => {
					try {
						await axios
							.get(
								`/api/store/get_ebo_ltl_monthwise_data_by_store?startDate=${startDate}&endDate=${endDate}&storeCode=${selectedStore.mapping_code}&brand=${selectedStore.brand}`
							)
							.then((res) => resolve(res.data));
					} catch (error) {
						reject(error);
					}
				});
				const saleDataSeasonWise = new Promise(async (resolve, reject) => {
					try {
						await axios
							.get(
								`/api/store/get_season_wise_store_sale_data?startDate=${startDate}&endDate=${endDate}&season=${selectedBp.name}&storeCode=${selectedStore.mapping_code}&brand=${selectedStore.brand}`
							)
							.then((res) => resolve(res.data));
					} catch (error) {
						reject(error);
					}
				});

				const last6MonthSaleData = new Promise(async (resolve, reject) => {
					try {
						await axios
							.get(
								`/api/store/get_EBO_last6month_sale_by_store?storeCode=${selectedStore.mapping_code}&brand=${selectedStore.brand}`
							)
							.then((res) => resolve(res.data[0]));
					} catch (error) {
						reject(error);
					}
				});
				// console.log(data);

				const data = await Promise.all([LTLDataMonthWise, saleDataSeasonWise, last6MonthSaleData]);
				console.log(data);
				const LTLData = data[0];
				const saleData = data[1];
				const last6MData = data[2];
				console.log(saleData);
				const st = saleData ? saleData[0].ST : 0;

				let calculatedLTLData = { revenue: 0, qty: 0, total_mrp: 0, asp: 0, dis: 0, st: 0, ros: 0, rtv: 0 };
				LTLData.forEach((element) => {
					calculatedLTLData = {
						revenue: calculatedLTLData.revenue + element.value,
						qty: calculatedLTLData.qty + element.qty,
						total_mrp: calculatedLTLData.total_mrp + element.mrp,
					};
				});
				setRepeatCustomers(
					roundDecimalValueUptoTwo(
						(saleData[0].REPEAT_CUSTOMER_COUNT * 100) /
							(saleData[0].REPEAT_CUSTOMER_COUNT + saleData[0].NEW_CUSTOMER_COUNT)
					)
				);
				calculatedLTLData = {
					...calculatedLTLData,
					range: "LTL AVG",
					asp: calculateAsp(calculatedLTLData.revenue, calculatedLTLData.qty),
					dis: calculateDiscount(calculatedLTLData.total_mrp, calculatedLTLData.revenue),
					ros: calculateRos(calculatedLTLData.qty),
					st: roundDecimalValueUptoTwo(st),
					rtv: roundDecimalValueUptoTwo(100 - st),
				};
				setLtlSeasonST(calculatedLTLData.st);
				setLTLDataMonthWise(LTLData);
				setTotalLtlData(calculatedLTLData);

				const updatedLast6MData = {
					revenue: last6MData.value,
					qty: last6MData.qty,
					total_mrp: last6MData.mrp,
					range: "6 M AVG",
					asp: calculateAsp(last6MData.value, last6MData.qty),
					dis: calculateDiscount(last6MData.mrp, last6MData.value),
					ros: calculateRos(last6MData.qty),
				};
				setTotalLast6MData({
					...updatedLast6MData,
					st: roundDecimalValueUptoTwo(st),
					rtv: roundDecimalValueUptoTwo(100 - st),
				});
			} catch (error) {}
			// last 6 m data
			try {
			} catch (error) {
				// console.log(error);
			}
		};

		fetch();
	}, [selectedStore]);

	// calculate growth percent of historical data
	useEffect(() => {
		if (totalLTLData) {
			let obj = growthRowData;
			obj[newTarget.range] = {
				revenue: newTarget ? ((newTarget.revenue - totalLTLData.revenue) / totalLTLData.revenue) * 100 : 0,
				asp: newTarget ? ((newTarget.asp - totalLTLData.asp) / totalLTLData.asp) * 100 : 0,
				dis: newTarget ? ((newTarget.dis - totalLTLData.dis) / totalLTLData.dis) * 100 : 0,
				ros: newTarget ? ((newTarget.ros - totalLTLData.ros) / totalLTLData.ros) * 100 : 0,
				st: newTarget ? ((newTarget.st - totalLTLData.st) / totalLTLData.st) * 100 : 0,
				rtv: newTarget ? ((newTarget.rtv - totalLTLData.rtv) / totalLTLData.rtv) * 100 : 0,
			};
			setPercentage(obj);
		}
		if (newTarget && derivedTarget) {
			let obj = {
				revenue: newTarget.revenue > 0 ? ((derivedTarget.revenue - newTarget.revenue) / newTarget.revenue) * 100 : 0,
				asp: newTarget.asp > 0 ? ((derivedTarget.asp - newTarget.asp) / newTarget.asp) * 100 : 0,
				dis: newTarget.dis > 0 ? ((derivedTarget.dis - newTarget.dis) / newTarget.dis) * 100 : 0,
				ros: derivedTarget.ros > 0 && newTarget.ros > 0 ? ((derivedTarget.ros - newTarget.ros) / newTarget.ros) * 100 : 0,
				st: newTarget.st > 0 ? ((derivedTarget.st - newTarget.st) / newTarget.st) * 100 : 0,
				rtv: newTarget.rtv > 0 ? ((derivedTarget.rtv - newTarget.rtv) / newTarget.rtv) * 100 : 0,
			};
			setGrowthRowData(obj);
		}
	}, [derivedTarget, newTarget, totalLTLData]);

	// calculate growth Value for store
	useEffect(() => {
		const fetch = async () => {
			try {
				// to calculate growth to previous season
				const { data: LTLPrvSeasonData } = await axios.get(
					`/api/store/get_ebo_ltl_Prv_season_data_by_store?storeCode=${selectedStore.mapping_code}`
				);
				if (LTLPrvSeasonData.length && LTLPrvSeasonData.length > 0)
					setGrowthToPrvSeason(
						roundDecimalValueUptoTwo(((LTLPrvSeasonData[0].qty - totalLast6MData.qty) / LTLPrvSeasonData[0].qty) * 100)
					);
			} catch (error) {
				// console.log(error);
			}
		};
		if (totalLast6MData) fetch();
	}, [totalLast6MData]);

	// const getTotalSaleMonthwiseByStore = async () => {
	// 	await axios.get(
	// 		`/api/query_run/top_mrp_by_store_division?startDate=${startDate}&endDate=${endDate}&storeCode=${selectedStore.mapping_code}`
	// 	);
	// };

	const checkforEmptyFields = () => {
		if (
			// parseInt(newTarget.st) === 0 ||
			parseInt(newTarget.asp) === 0 ||
			parseInt(newTarget.dis) === 0
		)
			return false;
		const monthWiseEmptyCount = bpMonths.filter((month) => parseInt(monthWiseProjection[`month_${month.key}`]) === 0).length;
		if (monthWiseEmptyCount > 0) return false;
		return true;
	};
	const handleSaveClick = async () => {
		const emptyFields = checkforEmptyFields();
		console.log(user);
		if (user.role[selectedStore.stage] !== true) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: `Stage Mismatch,Store is at ${selectedStore.stage} Stage`,
				severity: "error",
			});
			return;
		}
		if (!emptyFields) {
			setSnackBarOpen(true);
			setSnackBar({ ...snackBar, message: "Fill All Manual projection Fields", severity: "error" });
			return;
		}

		let target = { ...newTarget, completed: false };
		let monthWiseProjectionObj = { ...monthWiseProjection, is_completed: true };
		try {
			const body = { monthWiseProjection: monthWiseProjectionObj, target };
			await axios.put("/api/business_plan/save_store_manual_details", body).then((res) => {
				setSnackBarOpen(true);
				setSnackBar({ ...snackBar, message: "Saved", severity: "success" });
				setSelectedStore({
					...selectedStore,
					storeProjection: [{ ...monthWiseProjection }],
					storeSeasonWiseData: [derivedTarget, target],
				});
				// setNewTarget({ ...target });
				// setMonthWiseProjection(monthWiseProjectionObj);
			});
		} catch (error) {
			setSnackBarOpen(true);
			// console.log(error);
			const response = error.response;
			if (response && response.data && response.data.status === "error")
				setSnackBar({ ...snackBar, message: response.data.error, severity: "error" });
			else setSnackBar({ ...snackBar, message: error.message, severity: "error" });
		}
	};
	const handleFinalSubmitClick = async (dataStage) => {
		setLoading(true);
		const emptyFields = checkforEmptyFields();
		if (!derivedTarget.revenue > 0) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Fill Mrp Level Projection .Derived Target Cannot be 0",
				severity: "error",
			});
			setLoading(false);

			return;
		}
		if (!emptyFields) {
			setSnackBarOpen(true);
			setSnackBar({ ...snackBar, message: "Fill All Manual projection Fields", severity: "error" });
			setLoading(false);

			return;
		}
		let monthWiseProjectionObj = { ...monthWiseProjection, is_completed: true };
		if (user.role[selectedStore.stage] !== true) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: `Stage Mismatch,Store is at ${selectedStore.stage} Stage`,
				severity: "error",
			});
			setLoading(false);

			return;
		}
		let target = { ...newTarget, completed: true };
		try {
			const body = { monthWiseProjection: monthWiseProjectionObj, target };
			await axios.put("/api/business_plan/save_store_manual_details", body).then(async (res) => {
				setSnackBarOpen(true);
				setSnackBar({ ...snackBar, message: "Saved", severity: "success" });

				await axios
					.put(`/api/business_plan/save_store_final_submit/${selectedStore.id}?dataStage=${dataStage}`)
					.then((res) => {
						setLoading(false);
						setSnackBar({ ...snackBar, message: "Store Submitted Successfully", severity: "success" });
						setSelectedStore({ ...res.data });
					});

				// setNewTarget({ ...target });
				// setMonthWiseProjection(monthWiseProjectionObj);
			});
		} catch (error) {
			setSnackBarOpen(true);
			// console.log(error);
			const response = error.response;
			if (response && response.data && response.data.status === "error")
				setSnackBar({ ...snackBar, message: response.data.error, severity: "error" });
			else setSnackBar({ ...snackBar, message: error.message, severity: "error" });
			setLoading(false);
		}
	};

	const handleEditClick = () => {
		setEditModal(true);
	};

	if (selectedStore && selectedBp) {
		return (
			<Stack width="1200px" margin="auto" pt={2} spacing={1}>
				<Button
					startIcon={<KeyboardBackspaceRounded />}
					variant="outlined"
					onClick={() => navigate(-1)}
					sx={{ mb: 2, width: 100 }}>
					Back
				</Button>
				<Breadcrumbs cursor="pointer" sx={{ width: "100%" }}>
					<Typography variant="h5" sx={{ fontSize: 26 }}>
						BUSINESS PLAN
					</Typography>

					<Typography variant="h5">
						{selectedBp.name}-<span style={{ color: "#800000" }}>{selectedStore.stage}</span>
					</Typography>
				</Breadcrumbs>
				{/* top Info */}
				<Box
					display="flex"
					width="100%"
					justifyContent="space-around"
					// pb={1}
					borderBottom={`1px solid ${grey[200]}`}>
					{/* left Section */}
					<Stack width="40%" spacing={0}>
						<Box sx={storeInfoBox}>
							<Typography sx={storeInfoColumn}>
								Store Name :
								<span style={{ fontSize: 17, fontWeight: "bold" }}>
									{" "}
									{selectedStore.store_name} - {selectedStore.brand}
								</span>
							</Typography>
							<Typography sx={storeInfoColumn}>
								Store Code : <span style={{ fontSize: 17, fontWeight: "bold" }}>{selectedStore.mapping_code}</span>
							</Typography>
						</Box>

						<Box sx={storeInfoBox}>
							<Typography sx={storeInfoColumn}>
								Age :{" "}
								<span style={{ fontSize: 18, fontWeight: "bold" }}>{calculateStoreAge(selectedStore.open_date)}</span>Y
							</Typography>
							<Typography sx={storeInfoColumn}>
								Store Grade :<span style={{ fontSize: 18, fontWeight: "bold" }}>{selectedStore.store_grade}</span>
							</Typography>
						</Box>

						<Box sx={storeInfoBox}>
							<Typography sx={storeInfoColumn}>
								Square Feet Area : <span style={{ fontSize: 18, fontWeight: "bold" }}>{selectedStore.area}</span>
							</Typography>
							<Typography sx={storeInfoColumn}>
								SPSF:{" "}
								<span style={{ fontSize: 18, fontWeight: "bold" }}>
									₹ {totalLTLData ? roundDecimalValueUptoTwo(totalLTLData.revenue / selectedStore.area / 6) : 0}
								</span>{" "}
								<span style={{ fontSize: 14 }}>/M</span>
							</Typography>
						</Box>
						<Box sx={storeInfoBox}>
							{/* <Typography sx={storeInfoColumn}>
								Festival Count :<span style={{ fontSize: 18, fontWeight: "bold" }}> 3</span> /
								<span style={{ fontSize: 18, fontWeight: "bold" }}> 1</span>
							</Typography> */}
							<Typography sx={storeInfoColumn}>
								City :<span style={{ fontSize: 18, fontWeight: "bold" }}> {selectedStore.city_tier}</span>
							</Typography>
						</Box>
						<Box sx={{ height: "10px", borderBottom: `1px solid ${grey[200]}`, width: "60%", mb: 1 }}></Box>
						<Box sx={textInfo} display="flex" alignItems="center" columnGap={1}>
							<span>GW to Previous Season : </span>
							<span
								style={{
									fontSize: 18,
									fontWeight: "bold",
									color: growthToPrvSeason && growthToPrvSeason > 0 ? green["A700"] : red["A700"],
								}}>
								{growthToPrvSeason ? growthToPrvSeason : 0} %
							</span>
						</Box>

						<Typography sx={textInfo}>
							Repeat Customers :<span style={{ fontSize: 18, fontWeight: "bold" }}>{repeatCustomers}%</span>
						</Typography>
					</Stack>
					{/* right Section Table */}
					<Stack width="60%">
						{/* heading */}
						<Box display="flex">
							<Box sx={{ ...tableBox, width: "160px" }}>
								<Typography sx={{ ...textInfo, fontWeight: "bold" }}>RANGE</Typography>
							</Box>

							<Box sx={tableBox}>
								<Typography sx={{ ...textInfo, fontWeight: "bold" }}>REV</Typography>
							</Box>

							<Box sx={tableBox}>
								<Typography sx={{ ...textInfo, fontWeight: "bold" }}>ASP</Typography>
							</Box>

							<Box sx={tableBox}>
								<Typography sx={{ ...textInfo, fontWeight: "bold" }}>DIS</Typography>
							</Box>

							<Box sx={tableBox}>
								<Typography sx={{ ...textInfo, fontWeight: "bold" }}>ROS</Typography>
							</Box>

							{/* <Box sx={tableBox}>
								<Typography sx={{ ...textInfo, fontWeight: "bold" }}>ST</Typography>
							</Box> */}

							{/* <Box sx={tableBox}>
								<Typography sx={{ ...textInfo, fontWeight: "bold" }}>RTV</Typography>{" "}
							</Box> */}
						</Box>

						{/* ltl Data */}
						<SeasonInfoTableRow row={totalLTLData} displayPercent={false} />
						{/* 6M Data */}
						<SeasonInfoTableRow row={totalLast6MData} displayPercent={false} />

						{/* New target */}
						<Box
							key={percentage}
							display="flex"
							alignItems="center"
							sx={
								selectedStore.stage !== custom.RSM.key
									? {
											// background: deepOrange["A200"],
											boxShadow: "1px 1px 4px lightgrey",
											border: `1px solid ${red[900]}`,
											padding: "7px 5px",
											borderRadius: "4px",
									  }
									: { background: grey[200], boxShadow: "1px 1px 4px lightgrey", padding: "7px 5px" }
							}>
							<Box sx={{ ...tableBox, width: "160px" }}>
								<Typography sx={{ ...textInfo, fontWeight: "bold" }}>NEW TARGET</Typography>
							</Box>
							<Box sx={tableBox}>
								{newTarget ? (
									<GrowthPercent
										GrowthPercent={percentage[newTarget.range].revenue}
										number={newTarget.revenue}
										inLakhs={true}
									/>
								) : (
									<HistoryLoadingSkeleton sx={loadingSkeleton} variant="rounded" />
								)}
							</Box>
							<Box sx={tableBox}>
								{newTarget ? (
									!newTarget.completed ? (
										<TextField
											size="small"
											value={newTarget.asp}
											onChange={(e) => setNewTarget({ ...newTarget, asp: Number(e.target.value) })}
											variant="outlined"
											fullWidth
										/>
									) : (
										<GrowthPercent GrowthPercent={percentage[newTarget.range].asp} number={newTarget.asp} />
									)
								) : (
									<HistoryLoadingSkeleton sx={loadingSkeleton} variant="rounded" />
								)}
							</Box>

							<Box sx={tableBox}>
								{newTarget ? (
									!newTarget.completed ? (
										<TextField
											size="small"
											InputProps={{
												endAdornment: <InputAdornment position="start">%</InputAdornment>,
											}}
											value={newTarget.dis}
											onChange={(e) => setNewTarget({ ...newTarget, dis: Number(e.target.value) })}
											variant="outlined"
											fullWidth
										/>
									) : (
										<GrowthPercent
											GrowthPercent={percentage[newTarget.range].dis}
											number={newTarget.dis}
											percent={true}
										/>
									)
								) : (
									<HistoryLoadingSkeleton sx={loadingSkeleton} variant="rounded" />
								)}
							</Box>
							{/* ros */}
							<Box sx={tableBox}>
								{newTarget ? (
									<Typography sx={textInfo}>
										---
										{
											// roundDecimalValueUptoTwo(newTarget.ros)
										}
									</Typography>
								) : (
									<HistoryLoadingSkeleton sx={loadingSkeleton} variant="rounded" />
								)}
							</Box>
							{/* st */}
							{/* <Box sx={tableBox}>
								{newTarget ? (
									!newTarget.completed ? (
										<TextField
											size="small"
											InputProps={{
												endAdornment: <InputAdornment position="start">%</InputAdornment>,
											}}
											value={newTarget.st}
											onChange={(e) =>
												setNewTarget({
													...newTarget,
													st: Number(e.target.value),
													rtv: Math.abs(Number(e.target.value - 100)),
												})
											}
											variant="outlined"
											fullWidth
										/>
									) : (
										<GrowthPercent
											GrowthPercent={percentage[newTarget.range].st}
											number={newTarget.st}
											percent={true}
										/>
									)
								) : (
									<HistoryLoadingSkeleton sx={loadingSkeleton} variant="rounded" />
								)}
							</Box> */}
							{/* RTV */}
							{/* <Box sx={tableBox}>
								{newTarget ? (
									<GrowthPercent
										GrowthPercent={percentage[newTarget.range].rtv}
										number={newTarget.rtv}
										percent={true}
									/>
								) : (
									<HistoryLoadingSkeleton sx={loadingSkeleton} variant="rounded" />
								)}
							</Box> */}
						</Box>

						{/* derived */}
						<SeasonInfoTableRow row={derivedTarget} LTLData={totalLTLData} displayPercent={true} />

						{/* growth */}
						<SeasonInfoGrowthRow row={growthRowData} />
						{/* Submit Button */}
						<Box p={1} width="100%" display="flex" justifyContent="flex-end" boxSizing="border-box" columnGap={2}>
							<Button
								variant="outlined"
								sx={{ width: "100px", textTransform: "capitalize" }}
								onClick={handleSaveClick}
								disabled={!user.role[selectedStore.stage]}>
								Save
							</Button>
							<Button
								variant="contained"
								sx={{ width: "180px", textTransform: "capitalize" }}
								onClick={() => setEditModal(true)}>
								{!user.role[selectedStore.stage] ? "View Data" : "Review and Submit"}
							</Button>
						</Box>
					</Stack>
				</Box>

				{/* sales Projection */}
				<Typography variant="h5" p={0}>
					Sales Projection
				</Typography>
				<Box>
					<Stack border={`1px solid ${grey[300]}`}>
						<Box
							width="100%"
							display="flex"
							justifyContent="space-between"
							boxShadow={`1px 1px 5px ${grey[300]}`}
							sx={{ background: "#f5f5f5" }}
							alignItems="center"
							borderBottom={`1px solid ${grey[300]}`}>
							<Typography
								sx={mrpBox}
								textAlign="center"
								height="42px"
								display="flex"
								alignItems="center"
								justifyContent="center">
								{selectedBp.season}-{parseInt(selectedBp.name.split("-")[1]) - 1}
								<span style={{ fontSize: "12px" }}>{"(LAKHS)"}</span>
							</Typography>
							{bpMonths.map((row) => {
								const monthData =
									LTLDataMonthWise && LTLDataMonthWise.length > 0
										? LTLDataMonthWise.filter((month) => row.key === month.month)[0]
										: null;
								return (
									<Stack sx={mrpBox} alignItems="center">
										<Typography fontSize={11} textTransform="uppercase">
											{row.name}
										</Typography>
										{monthData ? (
											<Box display="flex" columnGap={1} alignItems="baseline" justifyContent="space-between">
												<Typography fontSize={18}>
													{roundDecimalValueUptoTwo(monthData.value / 100000)}
												</Typography>
												{/* <Typography color={lightGreen[600]}>2%</Typography> */}
											</Box>
										) : (
											<Skeleton sx={{ width: 50, height: 30 }} minHeight="100%" variant="rounded" />
										)}
									</Stack>
								);
							})}
							{/* total */}
							<Stack alignItems="center" sx={mrpBox}>
								<Typography fontSize={11}>TOTAL</Typography>
								{totalLTLData ? (
									<Box display="flex" columnGap={1} alignItems="baseline" justifyContent="space-between">
										<Typography fontSize={18}>
											{roundDecimalValueUptoTwo(totalLTLData.revenue / 100000)}
										</Typography>
										{/* <Typography color={lightGreen[600]}>2%</Typography> */}
									</Box>
								) : (
									<Skeleton sx={{ width: 50, height: 30 }} minHeight="100%" variant="rounded" />
								)}
							</Stack>
						</Box>

						<Box
							width="100%"
							display="flex"
							justifyContent="space-between"
							alignItems="center"
							sx={
								selectedStore.stage !== custom.RSM.key && {
									// background: deepOrange[100],
									boxShadow: "1px 1px 4px lightgrey",
									border: `1px solid ${red[900]}`,
									borderRadius: "4px",
								}
							}>
							<Typography sx={mrpBox} textAlign="center">
								{selectedBp.name} Projection <span style={{ fontSize: "12px" }}>{"(LAKHS)"}</span>
							</Typography>
							{bpMonths &&
								bpMonths.map((row) => {
									const ltl_gw =
										LTLDataMonthWise &&
										LTLDataMonthWise.length > 0 &&
										LTLDataMonthWise.filter((ltl) => row.key === ltl.month)[0]
											? LTLDataMonthWise.filter((ltl) => row.key === ltl.month)[0].value / 100000
											: 1;

									return (
										<Box display="flex" sx={mrpBox} alignItems="center" justifyContent="center" columnGap={1}>
											<NumberTextField
												disableHelperText
												size="small"
												value={monthWiseProjection ? monthWiseProjection[`month_${row.key}`] : 0}
												onChange={(e) => {
													const updatedProjection = { ...monthWiseProjection };
													updatedProjection[`month_${row.key}`] = e.target.value;
													updatedProjection.total_projection = roundDecimalValueUptoTwo(
														updatedProjection.total_projection -
															monthWiseProjection[`month_${row.key}`] +
															parseFloat(e.target.value)
													);

													// console.log(
													// 	"abcd",
													// 	typeof monthWiseProjection[`month_${row.key}`],
													// 	typeof e.target.value,
													// 	updatedProjection.total_projection
													// );
													setNewTarget({
														...newTarget,
														revenue: updatedProjection.total_projection * 100000,
													});
													console.log(updatedProjection);
													setMonthWiseProjection({ ...updatedProjection });
												}}
											/>
											{/* <TextField size="small" variant="outlined" /> */}
											<GrowthPercentage
												textStyle={{ fontSize: "15px" }}
												number={
													monthWiseProjection &&
													ltl_gw &&
													roundDecimalValueUptoTwo(
														monthWiseProjection[`month_${row.key}`] > 0
															? ((monthWiseProjection[`month_${row.key}`] - ltl_gw) / ltl_gw) * 100
															: 0
													)
												}
											/>
										</Box>
									);
								})}
							<Box display="flex" sx={mrpBox} alignItems="center" justifyContent="center" columnGap={1}>
								<Typography variant="body1" fontSize={18}>
									{monthWiseProjection ? monthWiseProjection.total_projection : 0}
								</Typography>
								<GrowthPercentage
									textStyle={{ fontSize: "15px" }}
									number={
										newTarget && totalLTLData
											? roundDecimalValueUptoTwo(
													((newTarget.revenue - totalLTLData.revenue) / totalLTLData.revenue) * 100
											  )
											: 0
									}
								/>
							</Box>
						</Box>
					</Stack>
				</Box>

				{/* Top Mrp */}
				{/* <Typography variant="h5">Top Selling MRP</Typography>
				<Tabs value={tab} onChange={(e, newValue) => setTab(newValue)} aria-label="basic tabs example">
					{divisionData &&
						divisionData.map((row) => (
							<Tab label={row.name} sx={{ background: grey[100] }} value={row.key} />
						))}
				</Tabs> */}
				{/* <TopSellingMrpTable division={tab} bpMonths={bpMonths} storeCode={selectedStore.mapping_code} /> */}

				{/* All Mrp */}
				{/* <Typography variant="h5">All MRP</Typography>
				<Box display="flex" height="70px">
					{divisionData &&
						divisionData.map((row) => (
							<Stack
								spacing={0}
								key={row.key}
								alignItems="center"
								sx={{ padding: "10px", background: grey[100] }}
								onClick={() => setAllMrpTab(row.name)}>
								<Typography variant="subtitle1" sx={{ p: 0, m: 0 }}>
									{row.name}
								</Typography>
								<Typography sx={{ p: 0, m: 0, fontSize: "14px" }}>
									{row.name === "APPARELS" ? "90%" : row.name === "ADDONS" ? "8%" : "2%"}
								</Typography>
							</Stack>
						))}
				</Box> */}
				{/* <AllSellingMrpTable division={allMrpTab} bpMonths={bpMonths} storeCode={selectedStore.mapping_code} /> */}

				{/* Sales Projection */}

				{/* <Box p={4} width="100%" display="flex" justifyContent="center" boxSizing="border-box" columnGap={2}>
					<Button variant="outlined" sx={{ width: "100px" }}>
						Save
					</Button>
				</Box> */}
				<Typography variant="h5" pt={5}>
					MRP Level Projection
				</Typography>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Tabs
						value={tab}
						onChange={(e, newValue) => setTab(newValue)}
						sx={{ background: grey[100], borderRadius: 2, width: 310 }}>
						{divisionData &&
							divisionData.map((row) => (
								<Tab
									label={
										<Box sx={{ fontSize: "14px" }}>
											{row.name} <br />{" "}
											<span style={{ fontSize: "13px" }}>
												{divisionWiseContri ? divisionWiseContri[row.name] : 0} %
											</span>
										</Box>
									}
									sx={{ borderRadius: 3 }}
									value={row.key}
								/>
							))}
					</Tabs>

					<Typography>
						completed {selectedStore.current_count}/{selectedStore.total_count}
					</Typography>
				</Box>
				<CategoryTable division={tab} storeCode={selectedStore.mapping_code} />

				{/* <Dialog maxWidth="md" open={editModal} onClose={() => setEditModal(false)}>
					<DialogTitle>Edit Projection</DialogTitle>
					<EditManualProjectionModal />
					<DialogActions>
						<Button>Close</Button>
					</DialogActions>
				</Dialog> */}

				<Dialog maxWidth="md" open={editModal} onClose={() => setEditModal(false)}>
					<Box display="flex" justifyContent="space-between" alignItems="center">
						<DialogTitle alignSelf="center">Review Projection</DialogTitle>
						<Box p={2}>
							<IconButton onClick={() => setEditModal(false)}>
								<ClearRoundedIcon />
							</IconButton>
						</Box>
					</Box>
					{/* <EditManualProjectionModal /> */}
					<Box display="flex">
						<Box sx={{ ...tableBox, width: "160px" }}>
							<Typography sx={{ ...textInfo, fontWeight: "bold" }}>RANGE</Typography>
						</Box>

						<Box sx={tableBox}>
							<Typography sx={{ ...textInfo, fontWeight: "bold" }}>REV</Typography>
						</Box>

						<Box sx={tableBox}>
							<Typography sx={{ ...textInfo, fontWeight: "bold" }}>ASP</Typography>
						</Box>

						<Box sx={tableBox}>
							<Typography sx={{ ...textInfo, fontWeight: "bold" }}>DIS</Typography>
						</Box>

						<Box sx={tableBox}>
							<Typography sx={{ ...textInfo, fontWeight: "bold" }}>ROS</Typography>
						</Box>

						<Box sx={tableBox}>
							<Typography sx={{ ...textInfo, fontWeight: "bold" }}>ST</Typography>
						</Box>

						<Box sx={tableBox}>
							<Typography sx={{ ...textInfo, fontWeight: "bold" }}>RTV</Typography>{" "}
						</Box>
					</Box>
					<SeasonInfoTableRow row={totalLTLData} />

					{selectedStore.stage !== custom.RSM.key ? (
						<Box>
							<Typography textAlign="center">RSM</Typography>
							<SeasonInfoTableRow
								row={
									selectedStore.storeSeasonWiseData.filter(
										(row) => row.stage === custom.RSM.key && row.is_new_target === true
									)[0]
								}
								LTLData={totalLTLData}
								displayPercent={true}
							/>
							<SeasonInfoTableRow
								row={
									selectedStore.storeSeasonWiseData.filter(
										(row) => row.stage === custom.RSM.key && row.is_new_target === false
									)[0]
								}
								LTLData={totalLTLData}
								displayPercent={true}
							/>

							<Typography textAlign="center">NSM</Typography>
							<SeasonInfoTableRow row={newTarget} LTLData={totalLTLData} displayPercent={true} />
							<SeasonInfoTableRow row={derivedTarget} LTLData={totalLTLData} displayPercent={true} />
						</Box>
					) : (
						<>
							{" "}
							<SeasonInfoTableRow row={newTarget} LTLData={totalLTLData} displayPercent={true} />
							<SeasonInfoTableRow row={derivedTarget} LTLData={totalLTLData} displayPercent={true} />
						</>
					)}
					{loading && (
						<Box margin="auto">
							<CircularProgress />
						</Box>
					)}
					<DialogActions sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
						{user.role[selectedStore.stage] === true && user.role[custom.NSM.key] === true && (
							<Button
								variant="outlined"
								disabled={loading}
								sx={{ justifySelf: "center" }}
								onClick={() => handleFinalSubmitClick(custom.RSM.key)}>
								Approve RSM Data
							</Button>
						)}
						{user.role[selectedStore.stage] === true &&
							((user.role[custom.RSM.key] && (
								<Button
									disabled={loading}
									variant="contained"
									sx={{ justifySelf: "center" }}
									onClick={() => handleFinalSubmitClick(custom.RSM.key)}>
									Submit Target
								</Button>
							)) ||
								(user.role[custom.NSM.key] && (
									<Button
										disabled={loading}
										variant="contained"
										sx={{ justifySelf: "center" }}
										onClick={() => handleFinalSubmitClick(custom.NSM.key)}>
										Submit New NSM Data
									</Button>
								)))}
					</DialogActions>
				</Dialog>
			</Stack>
		);
	} else navigate(`/${rootProtectedPath}/${services.business_plan.path}`);
};

export default Store;
