import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";

import { useState } from "react";
import { GlobalState } from "../../../context/GlobalContext";
import { brandList, channelData } from "../../../data/businessPlanningData";
import axios from "axios";
import { BusinessPlanState } from "../../../context/BusinessPlanningContext";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { CircularProgress } from "@mui/material";

// const bpStores = [
// 	{
// 		store_code: "RR001",
// 		store_name: "UB CITY",
// 		channel: "EBO",
// 		brand: "RR",
// 		open_date: "2015-08-15",
// 		asm: "Bhupendra",
// 		growth_to_prev_season: 0,
// 		store_grade: ".",
// 		repeat_customers: 0,
// 		last_6_Month_avg: 0,
// 		ltl_season: 0,
// 		sq_feet_area: 982,
// 		square_feet_sales_per_month: 0,
// 		festival_count: 3,
// 		tier: "TIER-1",
// 		bpStoreProjection: [{ level: "ASM" }],
// 	},
// ];

function CreateBusinessPlan({ setModalOpen, scrollToStores, setAnchorEl }) {
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const { allBp, setAllBp, setSelectedBp } = BusinessPlanState();
	const [season, setSeason] = useState("select");
	const [year, setYear] = useState(0);
	const [channel, setChannel] = useState("select");
	const [loading, setLoading] = useState(false);

	const getBpStores = async () => {
		let rsmStores = [];

		// axios request
		const res = await axios.get(`/api/store/get_by_channel?channel=${channel}`);
		const stores = res.data
			?.filter((row) => row?.STATUS === "OPERATIONAL")
			?.map((row) => {
				const body = {
					...row,
					stage: "RSM",
					brand: row.BRAND,
					open_date: row.OPEN_DATE ? row.OPEN_DATE : new Date(),
					area: row.CARPET_AREA,
					bh_email_id: row.BH_EMAIL_ID,
					bh_name: row.BH_NAME,
					bh_rb_Password: "",
					channel: "EBO",
					city: row.CITY,
					city_tier: row.CITY_TIER,
					erp_code: "",
					pin: "",
					rsm_email_id: row.RSM_EMAIL_ID,
					rsm_name: row.RSM_NAME,
					rsm_password: "",
					sl_no: "",
					state: row.State,
					state_zone: row.REGION,
					store_code: row.SITECODE,
					store_grade: row.STORE_GRADE,
					store_name: row.STORE_NAME,
					status: "active",
					store_type: row.Type,
					mapping_code: row.SITECODE,
				};
				if (body.brand === "THOR") {
					rsmStores.push({
						...body,
						brand: "RAREISM",
						rsm_name: row.RSM_NAME?.split(",")[1] ?? "ABHISHEK",
						rsm_email_id: row?.RSM_EMAIL_ID?.split(",")[1] ?? "abhishek.s@thehouseofrare.com",
						// rsm_password: "bharatThor123",
						bh_name: "ABHISHEK",
						bh_email_id: "abhishek.s@thehouseofrare.com",
						// bh_rb_Password: "abhishekThor432",
					});
					return {
						...body,
						brand: "RARE RABBIT",
						bh_name: "VINEETA",
						bh_email_id: "vineeta@thehouseofrare.com",
						rsm_name: row.RSM_NAME?.split(",")[0] ?? "VINEETA",
						rsm_email_id: row?.RSM_EMAIL_ID?.split(",")[0] ?? "vineeta@thehouseofrare.com",
					};
				} else return body;
			});
		return [...stores, ...rsmStores];
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		if (season === "select" || year === 0 || channel === "select") {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				autoHideDuration: 20000,
				message: "Select all the fields",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		}
		const bpStores = await getBpStores();

		try {
			const body = {
				name: season + "-" + year.toString().substring(2, 4),
				season,
				year,
				channel,
				total_count: bpStores.length,
				bpStores,
			};
			if (bpStores.length)
				await axios.post("/api/business_plan", body).then((res) => {
					const newBp = res.data.result;
					setAllBp([newBp, ...allBp]);
					setSnackBarOpen(true);
					// set selected Bp after creating
					setSelectedBp(newBp);
					setSnackBar({
						...snackBar,
						message: "Plan Created Successfuly",
						severity: "success",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
					setLoading(false);
					setModalOpen(false);
					setAnchorEl(null);
					scrollToStores();
				});
			else throw new Error("Unable to fetch stores list");
		} catch (error) {
			setSnackBarOpen(true);
			const response = error.response;
			if (response && response.data && response.data.status === "error")
				setSnackBar({ ...snackBar, message: response.data.error, severity: "error" });
			else setSnackBar({ ...snackBar, message: error.message, severity: "error" });
			// setModalOpen(false);
			setLoading(false);
		}
	};
	const handleCloseClick = () => {
		setModalOpen(false);
		setAnchorEl(null);
	};

	const date = new Date();

	return (
		<Stack component="form" onSubmit={handleSubmit} spacing={1}>
			<Box display="flex" alignItems="center" justifyContent="flex-end">
				{/* <Typography variant="h6">Create New Plan</Typography> */}
				<IconButton onClick={() => handleCloseClick()}>
					<ClearRoundedIcon />
				</IconButton>
			</Box>
			{/* form */}
			<Stack
				flexWrap="wrap"
				spacing={1}
				// sx={{ width: "100%" }}
				justifyContent="space-around">
				<Box sx={{ width: "100%" }}>
					<InputLabel>Select Season</InputLabel>
					<Select size="small" fullWidth value={season} onChange={(e) => setSeason(e.target.value)}>
						<MenuItem value={"select"}>Select Season</MenuItem>
						<MenuItem value={"AW"}>AW</MenuItem>
						<MenuItem value={"SS"}>SS</MenuItem>
					</Select>
				</Box>
				<Box sx={{ width: "100%" }}>
					<InputLabel>Select Year</InputLabel>
					<Select
						size="small"
						fullWidth
						value={year}
						MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
						onChange={(e) => setYear(e.target.value)}>
						<MenuItem value={0}>Select Year</MenuItem>
						<MenuItem value={date.getFullYear()}>{date.getFullYear()}</MenuItem>

						{[1, 2, 3, 4].map((row) => (
							<MenuItem key={row} value={date.getFullYear() + row}>
								{date.getFullYear() + row}
							</MenuItem>
						))}
					</Select>
				</Box>
				<Box sx={{ width: "100%" }}>
					<InputLabel>Select Channel</InputLabel>
					<Select
						size="small"
						fullWidth
						value={channel}
						MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
						onChange={(e) => setChannel(e.target.value)}>
						<MenuItem value={"select"}>Select Channel</MenuItem>
						{channelData &&
							channelData.map((row) => (
								<MenuItem key={row.key} value={row.key}>
									{row.name}
								</MenuItem>
							))}
					</Select>
				</Box>
			</Stack>

			<Box display="flex" alignItems="center">
				<Button
					startIcon={loading ? <CircularProgress color="inherit" size="25px" /> : <AddRoundedIcon />}
					type="submit"
					disabled={loading}
					variant="contained"
					sx={{ margin: "auto", width: 200, textTransform: "capitalize" }}
					disableElevation
					size="large">
					Create
				</Button>
			</Box>
		</Stack>
	);
}

export default CreateBusinessPlan;
