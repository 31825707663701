import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { eboStoreDrawerElements } from "components/EboStoreV4/SelectedStoreDashboard/data";
import { useNavigate, useOutletContext } from "react-router-dom";
import { KeyboardBackspaceRounded } from "@mui/icons-material";
import BasicDetails from "./DashboardComponents/BasicDetails/BasicDetails";
import FinanceDetails from "./DashboardComponents/FinanceDetails/FinanceDetails";
import EmployeeDetails from "./DashboardComponents/EmployeeDetails/EmployeeDetails";
import { EboStoreState } from "context/EboStoreContext";

const SelectedStoreDashboard = () => {
	const [tab, setTab] = useOutletContext();
	const { selectedStore, setSelectedStore } = EboStoreState() || {};
	const navigate = useNavigate();

	let isEbo = selectedStore?.eboStoreTypeDetails[0]?.channel === "EBO" ? true : false;
	const tabComponents = [
		{
			...eboStoreDrawerElements.store_details,
			component: <BasicDetails />,
		},
		{
			...eboStoreDrawerElements.finance_details,
			component: isEbo && <FinanceDetails />,
		},
		{
			...eboStoreDrawerElements.employee_details,
			component: isEbo && <EmployeeDetails />,
		},
	];
	return (
		<Box sx={{ width: "100%" }}>
			<Button
				startIcon={<KeyboardBackspaceRounded />}
				variant="outlined"
				onClick={() => navigate("/explore/ebo_stores/stores")}
				sx={{ m: 2 }}>
				Back
			</Button>
			{tabComponents &&
				tabComponents.map((row) => (
					<Box width="95%" margin="auto" key={row.key} display={row.key === tab ? "block" : "none"}>
						{tab === row.key && row.component}
					</Box>
				))}
		</Box>
	);
};

export default SelectedStoreDashboard;
