import { DownloadRounded } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, InputLabel, Stack, Typography, TextField } from "@mui/material";
import { grey } from "@mui/material/colors";
import axios from "axios";
import { GlobalState } from "context/GlobalContext";
import { paymentTermColumns } from "data/supplierPageData";
import FileSaver from "file-saver";
import { useState } from "react";
const formStack = {
	width: { xs: "80%", sm: "40%", md: "30%" },
};

const VerifyFinancialDetailsModal = ({ pageData, submitFunction, modalOpen, setModalOpen }) => {
	const [modifiedData, setModifiedData] = useState(pageData);
	const { setSnackBar, snackBar, setSnackBarOpen, setLoadingOpen } = GlobalState();

	const handleModalClose = () => {
		setModifiedData(pageData);
		setModalOpen(false);
	};
	const handleFormSubmit = (e) => {
		e.preventDefault();
		if (typeof submitFunction === "function") submitFunction(modifiedData.transaction_no);
	};

	const handleSnackBarCommon = (severity, message) => {
		setSnackBarOpen(true);
		setSnackBar({
			...snackBar,
			message: message,
			severity: severity,
			anchorOrigin: { horizontal: "center", vertical: "top" },
		});
	};

	const handleDownloadClick = async () => {
		setLoadingOpen(true);
		try {
			await axios.get(`/api/supplier/download_account_details/${pageData?.id}`, { responseType: "blob" }).then((res) => {
				console.log(res);
				const url = URL.createObjectURL(res.data);
				FileSaver.saveAs(url, `${pageData?.company_name}_account_details.xlsx`);
				handleSnackBarCommon("success", "Successfully download the file");
			});
		} catch (error) {
			handleSnackBarCommon("error", "Failed to download file");
		}
		setLoadingOpen(false);
	};
	return (
		<Dialog fullWidth maxWidth="md" open={modalOpen} onClose={handleModalClose} component="form" onSubmit={handleFormSubmit}>
			{pageData && pageData[paymentTermColumns.payment_mode.key] === "" ? (
				<>
					<DialogContent>{`Cannot Approve the details... Payment term is not assigned for the vendor`}</DialogContent>
					<DialogActions>
						<Button variant="outlined" onClick={() => handleModalClose()}>
							Cancel
						</Button>
					</DialogActions>
				</>
			) : (
				<>
					<Box display="flex" columnGap="3%" p={2} flexWrap="wrap">
						<Typography variant="h6" width="100%" pb={2}>
							Verify Payment Mode
						</Typography>
						<Stack className="vendor-input" sx={formStack} spacing={1}>
							<InputLabel>
								Payment Mode<span>*</span>:
							</InputLabel>
							<Typography variant="h6">{pageData && pageData[paymentTermColumns.payment_mode.key]}</Typography>
						</Stack>
						{/* {pageData && pageData[paymentTermColumns.payment_mode.key] === "NEFT" ? ( */}
						<Stack className="vendor-input" sx={{ width: { ...formStack.width, md: "60%" } }} spacing={1}>
							<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
								<Button
									disabled={false}
									startIcon={<DownloadRounded />}
									variant="contained"
									sx={{ width: "maxContent", height: "40px" }}
									onClick={handleDownloadClick}>
									{"Account Details"}
								</Button>
							</Box>
							<InputLabel>Transaction Number:</InputLabel>
							<TextField
								size="small"
								type="number"
								value={modifiedData?.transaction_no}
								onChange={(e) => setModifiedData({ ...modifiedData, transaction_no: e.target.value })}
								placeholder="Enter Transaction Number"
								required
								// helperText="Transaction number done for verification"
							/>
						</Stack>
						{/* ) : (
                        <></>
                    )} */}
						<Typography variant="subtitle2" width="100%" pt={2} color={grey[700]}>
							Note*
						</Typography>
						<Typography variant="subtitle2" width="100%" color={grey[700]}>
							For Cheque enter Cheque No.
						</Typography>
						<Typography variant="subtitle2" width="100%" color={grey[700]}>
							For NEFT and RTGS enter UTR No.
						</Typography>
					</Box>
					<DialogActions>
						<Button variant="outlined" onClick={() => handleModalClose()}>
							Cancel
						</Button>
						<Button variant="contained" type="submit">
							Verify
						</Button>
					</DialogActions>
				</>
			)}
		</Dialog>
	);
};

export default VerifyFinancialDetailsModal;
