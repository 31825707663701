import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { GlobalState } from "../../../context/GlobalContext";

import axios from "axios";

import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import SupplierInfoModal from "./SupplierInfoModal/SupplierInfoModal";
import ClearRounded from "@mui/icons-material/ClearRounded";
import { red, yellow } from "@mui/material/colors";
import { CancelOutlined, DownloadRounded } from "@mui/icons-material";
import FileSaver from "file-saver";

const supplierTablecolumns = [
	{
		field: "product_division",
		headerName: "Product Divisions",
		width: 120,
		headerAlign: "center",
		align: "center",
		valueGetter: (val) => {
			return val;
		},
	},
	{ field: "user_email", headerName: "Contact Person", width: 120, headerAlign: "center", align: "center" },
	{ field: "company_name", headerName: "Company", width: 100, headerAlign: "center", align: "center" },
	{ field: "company_type", headerName: "Type", width: 100, headerAlign: "center", align: "center" },
	{ field: "supplier_nature", headerName: "Nature", width: 120, headerAlign: "center", align: "center" },
	{ field: "industry_type", headerName: "Industry Type", width: 120, headerAlign: "center", align: "center" },
	{ field: "capacity_for_thor", headerName: "Capacity THOR", width: 120, headerAlign: "center", align: "center" },
	{ field: "monthly_capacity", headerName: "Monthly Capacity", width: 120, headerAlign: "center", align: "center" },
	{
		field: "finance_approved",
		headerName: "Finance Approval",
		width: 145,
		renderCell: (params) => {
			return (
				<Box
					sx={{
						backgroundColor: params.row.finance_approved
							? "#4caf50"
							: params.row.finance_approved === 0 && params.row.finance_remark
							? "#ef5350"
							: yellow[600],
						color: "white",
						padding: "5px",
						borderRadius: "5px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						height: "40px",
						boxSizing: "border-box",
					}}>
					{params.row.finance_approved ? (
						<Box display="flex" alignItems="center" columnGap={1}>
							<CheckCircleOutlineRoundedIcon /> Approved
						</Box>
					) : params.row.finance_approved === 0 && params.row.finance_remark ? (
						<Box display="flex" alignItems="center" columnGap={1}>
							<CancelOutlined />
							Rejected
						</Box>
					) : (
						<Box display="flex" alignItems="center" columnGap={1}>
							<ErrorOutlineRoundedIcon />
							Pending
						</Box>
					)}
				</Box>
			);
		},
	},
	{
		field: "is_approved",
		headerName: "Final Approval",
		width: 145,
		renderCell: (params) => {
			return (
				<Box
					sx={{
						backgroundColor: params.row.is_approved ? "#4caf50" : yellow[600],
						color: "white",
						padding: "5px",
						borderRadius: "5px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						height: "40px",
						boxSizing: "border-box",
					}}>
					{params.row.is_approved ? (
						<Box display="flex" alignItems="center" columnGap={1}>
							<CheckCircleOutlineRoundedIcon /> Approved
						</Box>
					) : (
						<Box display="flex" alignItems="center" columnGap={1}>
							<ErrorOutlineRoundedIcon />
							Pending
						</Box>
					)}
				</Box>
			);
		},
	},
	{
		field: "is_active",
		headerName: "Status",
		width: 145,
		renderCell: (params) => {
			return (
				<Box
					sx={{
						backgroundColor: params.row.is_active ? "#4caf50" : yellow[500],
						color: "white",
						padding: "5px",
						borderRadius: "5px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						height: "40px",
						boxSizing: "border-box",
					}}>
					<></>
					{params.row.is_active ? (
						<Box display="flex" alignItems="center" columnGap={1}>
							<CheckCircleOutlineRoundedIcon /> Active
						</Box>
					) : (
						<Box display="flex" alignItems="center" columnGap={1}>
							<ErrorOutlineRoundedIcon />
							inactive
						</Box>
					)}
				</Box>
			);
		},
	},
];

const SupplierDashboard = () => {
	const [allSuppliers, setAllSuppliers] = useState([]);
	const [selectedSupplier, setSelectedSupplier] = useState();

	// def value is true becoz for textfield disabled =true;
	const [modalEditable, setModalEditable] = useState(true);
	const [modalOpen, setModalOpen] = useState(false);

	const { setSnackBar, snackBar, setSnackBarOpen, setLoadingOpen } = GlobalState();

	const handleEditModalClose = () => {
		setModalEditable(true);
		setModalOpen(false);
	};

	useEffect(() => {
		const fetch = async () => {
			setLoadingOpen(true);
			try {
				await axios.get("/api/supplier").then((res) => {
					setAllSuppliers(res.data);
				});
			} catch (error) {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "Network Error",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			}
			setLoadingOpen(false);
		};
		fetch();
	}, []);

	const handleGridRowClick = (data) => {
		setModalOpen(true);
		setSelectedSupplier(data);
	};

	const handleSnackBarCommon = (severity, message) => {
		setSnackBarOpen(true);
		setSnackBar({
			...snackBar,
			message: message,
			severity: severity,
			anchorOrigin: { horizontal: "center", vertical: "top" },
		});
	};

	const handleDownloadClick = async () => {
		setLoadingOpen(true);
		try {
			await axios.get("/api/supplier/download_suppliers", { responseType: "blob" }).then((res) => {
				console.log(res);
				const url = URL.createObjectURL(res.data);
				FileSaver.saveAs(url, "All_suppliers.xlsx");
				handleSnackBarCommon("success", "Successfully download the file");
			});
		} catch (error) {
			handleSnackBarCommon("error", "Failed to download file");
		}
		setLoadingOpen(false);
	};
	return (
		<Stack maxWidth={{ lg: "90%", xs: "95%" }} spacing={3} margin="auto">
			<Stack display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
				<Typography variant="h4" width="100%" pt={4}>
					Supplier Dashboard
				</Typography>
				<Box pt={4} sx={{ display: "flex", alignItems: "center" }}>
					<Button
						disabled={false}
						startIcon={<DownloadRounded />}
						variant="contained"
						sx={{ width: 150, height: "40px" }}
						onClick={handleDownloadClick}>
						{"Download"}
					</Button>
				</Box>
			</Stack>

			{/* table */}
			<Stack width="100%">
				<DataGrid
					// key={allSuppliers}
					rows={allSuppliers}
					columns={supplierTablecolumns}
					sx={{
						minHeight: "75vh",
						"& .MuiDataGrid-row": {
							cursor: "pointer",
						},
						"& .MuiDataGrid-cell": {
							// padding: "10px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						},
					}}
					// rowHeight={40}
					// rowM
					// showCellVerticalBorder
					showColumnVerticalBorder
					onRowClick={(params) => {
						handleGridRowClick(params.row);
					}}
					initialState={{
						pagination: {
							paginationModel: { pageSize: 10 },
						},
					}}
					autoHeight
					disableRowSelectionOnClick
					pageSizeOptions={[5, 10]}
				/>
			</Stack>
			{/* edit modal */}
			<Dialog open={modalOpen} onClose={handleEditModalClose} scroll="body" maxWidth="md" fullWidth>
				<Box display="flex" justifyContent="space-between" p="5px 10px" alignItems="center">
					<Box display="flex" alignItems="center">
						<DialogTitle>Supplier Information</DialogTitle>
					</Box>
					<IconButton sx={{ width: "50px", height: "50px" }} onClick={handleEditModalClose}>
						<ClearRounded sx={{ transform: "scale(1.3)" }} />
					</IconButton>
				</Box>
				<Box p={2}>
					<SupplierInfoModal
						modalEditable={modalEditable}
						allSuppliers={allSuppliers}
						setAllSuppliers={setAllSuppliers}
						selectedSupplier={selectedSupplier}
						setSelectedSupplier={setSelectedSupplier}
						setModalOpen={setModalOpen}
						setModalEditable={setModalEditable}
					/>
				</Box>
			</Dialog>
		</Stack>
	);
};

export default SupplierDashboard;
