import { ClearRounded } from "@mui/icons-material";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	Divider,
	IconButton,
	InputLabel,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import AddFiles from "components/EboStoreV4/CommonComponents/AddFiles/AddFiles";
import CustomTextField from "components/EboStoreV4/CommonComponents/CustomTextfield/CustomTextfield";
import RenderInputField from "components/EboStoreV4/CommonComponents/RenderInputFiled/RenderInputFiled";
import {
	addOrEditModelAction,
	autoUpdatestoreLeaseFieldsData,
	autoUpdatestoreMainFieldsData,
	getColDefaultValue,
	leaseAgreementTableColumns,
	leaseDetails,
	mbolfsotherDetails,
	storeBasicDetailsFields,
	storeMainFields,
	storesLinkedTables,
	storeTypeDetails,
} from "components/EboStoreV4/data";
import { constraintsOptions } from "data/globalData";
import { inputTypes, statusOptions } from "data/pimData";
import { agreementData } from "data/supplierPageData";
import { useEffect, useState } from "react";

const AddOrEditModal = ({
	modal,
	setModal,
	type,
	selectedRow,
	createDetailsApi,
	selectedStore,
	setSelectedStore,
	handleUpdateDetails,
	handleGetStoreFiles,
	handleFileUploadDetails,
}) => {
	const defFields = (() => {
		let obj = {};
		mbolfsotherDetails?.forEach((attr) => {
			obj[attr.key] = getColDefaultValue(attr);
		});
		return obj;
	})();
	const [agreementDetails, setAgreementDetails] = useState(type === addOrEditModelAction.UPDATE ? selectedRow : defFields);
	const [agreementFile, setAgreementFile] = useState();

	const [allFiles, setAllFiles] = useState([]);

	const handleCallback = (data) => {
		setAllFiles(data);
	};

	useEffect(() => {
		selectedRow !== undefined &&
			handleGetStoreFiles(storesLinkedTables.mbo_lfs_other_details.key, selectedRow?.id, handleCallback);
	}, []);
	const handleFileChange = (e) => {
		const file = e.target.files[0];
		setAgreementFile(file);
	};

	const handleAddFile = (data) => {
		setAllFiles([...allFiles, data]);
	};

	const handleModalclose = () => {
		setModal(false);
	};
	const handleLeaseAgreeCallbackFun = (data) => {
		if (type === addOrEditModelAction.UPDATE) {
			let filtredData = selectedStore?.mbolfsOtherDetails.filter((data) => data.id !== agreementDetails.id);
			let leaseAgreement = [...filtredData, agreementDetails];
			setSelectedStore({ ...selectedStore, mbolfsOtherDetails: leaseAgreement });
		} else {
			let depositDetails = [...selectedStore?.mbolfsOtherDetails, data];
			setSelectedStore({ ...selectedStore, mbolfsOtherDetails: depositDetails });
		}
		setAgreementDetails(defFields);
		setModal(false);
	};
	const handleFormSubmit = async (e) => {
		e.preventDefault();
		const filtredFiles = allFiles.filter((data) => data?.isNew);
		//this if for file upload
		let updatedData = await handleFileUploadDetails(
			filtredFiles,
			selectedStore.store_code,
			storesLinkedTables.mbo_lfs_other_details.key
		);
		let updatedDeposit = { ...agreementDetails, ebo_store_v4_id: selectedStore.store_code, files: updatedData };
		if (type === addOrEditModelAction.UPDATE) {
			handleUpdateDetails(storesLinkedTables.mbo_lfs_other_details.seqMethod, updatedDeposit, handleLeaseAgreeCallbackFun);
		} else {
			createDetailsApi(storesLinkedTables.mbo_lfs_other_details.seqMethod, updatedDeposit, handleLeaseAgreeCallbackFun);
		}
	};

	return (
		<Dialog maxWidth="md" fullWidth open={modal} onClose={handleModalclose} component="form" onSubmit={handleFormSubmit}>
			<Box p={2}>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Typography variant="h6">{type === addOrEditModelAction.UPDATE ? "Update" : "Add"} Other Details</Typography>
					<Box>
						<IconButton onClick={handleModalclose}>
							<ClearRounded />
						</IconButton>
					</Box>
				</Box>
				<Stack spacing={1} width="100%">
					{" "}
					<Box display="flex" columnGap={"10px"} rowGap="10px" flexWrap="wrap" width="100%">
						{mbolfsotherDetails?.map((row) => {
							return (
								<RenderInputField
									column={row}
									data={agreementDetails}
									setData={setAgreementDetails}
									width="24%"
									selectOptObjName={"option_data"}
									dependencyColData={autoUpdatestoreLeaseFieldsData}
									disabled={row.key === "effective_date" && type === addOrEditModelAction.UPDATE}
								/>
							);
						})}
						{/* File Input Field */}
						{/* <Stack sx={{ width: "35%" }} spacing={"3px"} height={"70px"}>
							<InputLabel sx={{ color: grey[600] }}>Agreement File</InputLabel>
							<TextField
								variant="outlined"
								size="small"
								type="file"
								value={agreementFile}
								onChange={handleFileChange}
								placeholder="Add Agreement file"
								required={true}
								fullWidth
							/>
						</Stack> */}
						{/* <AddFiles
							columns={leaseAgreementTableColumns}
							tableName={storesLinkedTables.store_lease_agreement_details.key}
							rowId={selectedRow?.id}
							handleAddFile={handleAddFile}
							files={allFiles}
							selectedStore={selectedStore}
						/> */}
					</Box>
				</Stack>

				<DialogActions sx={{ pt: 2 }}>
					<Button variant="outlined" sx={{ justifySelf: "center" }} onClick={handleModalclose}>
						Cancel
					</Button>
					<Button
						variant="contained"
						sx={{ justifySelf: "center" }}
						disabled={selectedStore.status === statusOptions.PUBLISHED.key}
						type="submit">
						{type === addOrEditModelAction.UPDATE ? "UPDATE" : "CREATE"}
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
};

export default AddOrEditModal;
