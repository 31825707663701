import { ClearRounded } from "@mui/icons-material";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	Divider,
	IconButton,
	InputLabel,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import axios from "axios";
import CustomTextField from "components/EboStoreV4/CommonComponents/CustomTextfield/CustomTextfield";
import RenderInputField from "components/EboStoreV4/CommonComponents/RenderInputFiled/RenderInputFiled";
import {
	addOrEditModelAction,
	autoUpdatestoreMainFieldsData,
	getColDefaultValue,
	leaseDetails,
	storeBasicDetailsFields,
	storeMainFields,
	storesLinkedTables,
	storeTypeDetails,
} from "components/EboStoreV4/data";
import { useState } from "react";

const AddOrEditModal = ({ modal, setModal, type, defaultData, createDetailsApi, selectedStore, setSelectedStore }) => {
	const defFields = (() => {
		let obj = {};
		storeTypeDetails?.forEach((attr) => {
			obj[attr.key] = getColDefaultValue(attr);
		});
		return obj;
	})();
	const [formData, setFormData] = useState(type === addOrEditModelAction.UPDATE ? defaultData : defFields);

	const handleModalclose = () => {
		setModal(false);
	};
	const handleCallBackFun = () => {
		let updatedBody = { ...formData, ebo_store_v4_id: selectedStore.store_code };
		let eboStoreTypeDetails = selectedStore.eboStoreTypeDetails;
		eboStoreTypeDetails.unshift(updatedBody);
		setSelectedStore({ ...selectedStore, [eboStoreTypeDetails]: eboStoreTypeDetails });
		setFormData(defFields);
		setModal(false);
	};
	const handleFormSubmit = (e) => {
		e.preventDefault();
		let updatedBody = { ...formData, ebo_store_v4_id: selectedStore.store_code };
		delete updatedBody.id;
		delete updatedBody.createdAt;
		createDetailsApi(storesLinkedTables.store_type_details.seqMethod, updatedBody, handleCallBackFun);
	};
	return (
		<Dialog maxWidth="md" fullWidth open={modal} onClose={handleModalclose} component="form" onSubmit={handleFormSubmit}>
			<Box p={2}>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Typography variant="h6">{type === addOrEditModelAction.UPDATE ? "Update" : "Add"} Store Type Details</Typography>
					<Box>
						<IconButton onClick={handleModalclose}>
							<ClearRounded />
						</IconButton>
					</Box>
				</Box>
				<Stack spacing={1} width="100%">
					{" "}
					<Box display="flex" columnGap={"10px"} rowGap="10px" flexWrap="wrap" width="100%">
						{storeTypeDetails?.map((row) => {
							if (row.key === "code" && !["SELECT", ""].includes(formData["channel"])) {
								if (formData["channel"] !== "EBO") {
									row.constraints.DISPLAY = true;
								} else {
									row.constraints.DISPLAY = false;
								}
							} else if (row.key === "code") {
								row.constraints.DISPLAY = false;
							}
							if (row.key === "store_type") {
								if (formData["channel"] !== "EBO") {
									row.constraints.DISPLAY = false;
								} else {
									row.constraints.DISPLAY = true;
								}
							}
							return (
								<RenderInputField
									column={row}
									data={formData}
									setData={setFormData}
									width="24%"
									selectOptObjName={"option_data"}
									dependencyColData={autoUpdatestoreMainFieldsData}
								/>
							);
						})}
					</Box>
				</Stack>

				<DialogActions sx={{ pt: 2 }}>
					<Button variant="outlined" sx={{ justifySelf: "center" }} onClick={handleModalclose}>
						Cancel
					</Button>
					<Button variant="contained" sx={{ justifySelf: "center" }} type="submit">
						{type === addOrEditModelAction.UPDATE ? "Update" : "Add"}
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
};

export default AddOrEditModal;
