import * as React from "react";
import dayjs from "dayjs";
import Badge from "@mui/material/Badge";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";
import styled from "@emotion/styled";
import CustomCalender from "components/commonComponents/CustomCalender";
import { Box, Button, Stack } from "@mui/material";
import { arrType, datatypes, inputTypes } from "data/globalData";
import { useState } from "react";
import { useRef } from "react";
import { GlobalState } from "context/GlobalContext";
import axios from "axios";
import RenderInputField from "components/EboStoreV4/CommonComponents/RenderInputFiled/RenderInputFiled";

const OPTION_MAPPING = [
	{
		label: "POS",
		value: "POS",
	},
	{
		label: "HDFC MPR",
		value: "HDFCMPR",
	},
	{
		label: "MPR PINELAB",
		value: "PINELABMPR",
	},
	{
		label: "AMEX",
		value: "AMEX",
	},
	{
		label: "BANK STATEMENT",
		value: "BANKSTATEMENT",
	},
	{
		label: "PHONEPE",
		value: "PHONEPE",
	},
	{
		label: "UPI REFUND",
		value: "UPIREFUND",
	},
];

const StyledDateCalendar = styled(DateCalendar)({
	"&.MuiDateCalendar-root": {
		margin: "5px",
		height: "800px",
		width: "600px",
		maxHeight: "none",
		"& .MuiDayCalendar-weekDayLabel": {
			fontSize: "1rem",
		},
		'& div[role="row"]': {
			justifyContent: "space-around",
		},
		"& .MuiDayCalendar-slideTransition": {
			minHeight: "500px",
		},
		"& .MuiPickersDay-root": {
			height: "50px",
			width: "50px",
			fontSize: "1rem",
		},
	},
});

const fileTypeColumn = {
	name: "File Type",
	key: "file_type",
	input_type: inputTypes.SELECT.key,
	constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
	data: {},
	data_type: datatypes.STRING.key,
	sort_no: 1,
	group: "",
	option_data: {
		type: arrType,
		list: OPTION_MAPPING,
		getOptionLabel: (row) => {
			return { name: row.label, key: row.value };
		},
	},
};

function getRandomNumber(min, max) {
	return Math.round(Math.random() * (max - min) + min);
}

function ServerDay(props) {
	const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

	const isSelected = !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;

	return (
		<Badge
			key={props.day.toString()}
			overlap="circular"
			badgeContent={isSelected ? <DoneAllRoundedIcon color="success" fontSize="small" /> : undefined}>
			<PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
		</Badge>
	);
}

export default function UploadHistory() {
	const { Loadingopen, setLoadingOpen } = GlobalState();
	const [highlightedDays, setHighlightedDays] = useState([]);
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();
	const [data, setData] = useState("");

	const handleChange = (e) => {
		setData(e.target.value);
	};

	const handleFormSubmit = async (e) => {
		e.preventDefault();
		setLoadingOpen(true);
		await axios
			.get(`/api/pos_reco/get-distinct-dates?file_type=${data["file_type"]}`)
			.then((res) => {
				console.log(res);
				setHighlightedDays(res.data || []);
				setLoadingOpen(false);
			})
			.catch((err) => {
				console.log(err);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "Something went wrong",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setLoadingOpen(false);
			});
		console.log(data);
	};

	return (
		<Box display={"flex"} mt={2} justifyContent={"space-between"} columnGap={8}>
			<Stack component={"form"} rowGap={2} onSubmit={handleFormSubmit}>
				<RenderInputField
					// sx={{ width: "55%", p: 1, background: grey[200], fontWeight: 400, overflow: "hidden" }}
					data={data}
					setData={setData}
					column={fileTypeColumn}
					width={"400px"}
					onChange={handleChange}
					selectOptObjName={"option_data"}
				/>
				<Button type="submit" variant="contained" size="medium" sx={{ width: "50%" }}>
					Submit
				</Button>
			</Stack>
			<Stack display={highlightedDays.length !== 0 ? "" : "none"} key={highlightedDays}>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<CustomCalender highlightedDatesList={highlightedDays} />
				</LocalizationProvider>
			</Stack>
		</Box>
	);
}
