import React, { useEffect, useState, useRef } from "react";
import dayjs from "dayjs";
import Badge from "@mui/material/Badge";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import styled from "@emotion/styled";
import moment from "moment";

// Styled DateCalendar Component
const StyledDateCalendar = styled(DateCalendar)(({ theme, height, width }) => ({
	"&.MuiDateCalendar-root": {
		margin: "5px",
		height: height || "350px",
		width: width || "600px",
		maxHeight: "none",
		"& .MuiDayCalendar-weekDayLabel": {
			fontSize: "1rem",
		},
		'& div[role="row"]': {
			justifyContent: "space-around",
		},
		"& .MuiDayCalendar-slideTransition": {
			minHeight: "500px",
		},
		"& .MuiPickersDay-root": {
			height: "50px",
			width: "50px",
			fontSize: "1rem",
		},
	},
}));

function ServerDay(props) {
	const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

	const today = dayjs(); // Current date
	const isPastDate = day.isBefore(today, "day"); // Check if the day is in the past
	const isSelected = !outsideCurrentMonth && highlightedDays.indexOf(day.date()) >= 0;

	return (
		<Badge
			key={day.toString()}
			overlap="circular"
			badgeContent={
				isSelected ? (
					<CheckCircleIcon color="success" fontSize="small" /> // Mark with a tick
				) : !outsideCurrentMonth && isPastDate ? (
					<CancelIcon color="error" fontSize="small" /> // Mark with a cross
				) : null // No badge for dates outside the current month
			}>
			<PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
		</Badge>
	);
}

export default function CustomCalendar({ height, width, highlightedDatesList }) {
	const requestAbortController = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const [highlightedDays, setHighlightedDays] = useState([]);
	const [highlightedDatesMap, setHighlightedDatesMap] = useState({});

	useEffect(() => {
		const highlightedDatesMapObj = {};
		highlightedDatesList.forEach((date) => {
			const key = dayjs(date).format("YYYY-MM"); // Format the date as 'yyyy-mm'
			if (!highlightedDatesMapObj[key]) {
				highlightedDatesMapObj[key] = [];
			}
			highlightedDatesMapObj[key].push(dayjs(date).date());
		});
		setHighlightedDatesMap(highlightedDatesMapObj);
		setHighlightedDays(highlightedDatesMapObj[moment().format("YYYY-MM")] || []); // Get highlighted days for the current month
	}, [highlightedDatesList]);

	const fetchHighlightedDays = (date) => {
		console.log("first", highlightedDatesMap);
		console.log("second", highlightedDays);
		const key = date.format("YYYY-MM"); // Format the date as 'yyyy-mm'
		setHighlightedDays(highlightedDatesMap[key] || []); // Get highlighted days for the current month
		setIsLoading(false);
	};

	// useEffect(() => {
	// 	fetchHighlightedDays(dayjs());
	// 	return () => requestAbortController.current?.abort();
	// }, []);

	const handleMonthChange = (date) => {
		if (requestAbortController.current) {
			requestAbortController.current.abort();
		}

		setIsLoading(true);
		setHighlightedDays([]);
		fetchHighlightedDays(date);
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<StyledDateCalendar
				disabled
				height={height}
				width={width}
				loading={isLoading}
				onMonthChange={handleMonthChange}
				renderLoading={() => (
					<DayCalendarSkeleton
						sx={{
							"&.MuiDayCalendarSkeleton-root": {
								margin: "5px",
								height: height ?? "350px",
								width: width ?? "600px",
								maxHeight: "none",
							},
							"& .MuiDayCalendarSkeleton-week": {
								fontSize: "1rem",
							},
							"& div": {
								justifyContent: "space-around",
							},
							"& .MuiDayCalendarSkeleton-daySkeleton": {
								margin: "5px",
							},
						}}
					/>
				)}
				slots={{
					day: ServerDay,
				}}
				slotProps={{
					day: { highlightedDays },
				}}
			/>
		</LocalizationProvider>
	);
}
