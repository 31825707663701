import axios from "axios";
import FileSaver from "file-saver";
import { DataGrid } from "@mui/x-data-grid";
import { statusOptions } from "data/pimData";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { eboSelectedStorepageUrl, mbolfsSelectedStorepageUrl } from "../data";
import React, { useEffect, useState } from "react";
import { publishTableColumn } from "data/globalData";
import { GlobalState } from "context/GlobalContext.js";
import DownloadIcon from "@mui/icons-material/Download";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Button, Dialog, DialogActions, Stack, Typography } from "@mui/material";
import CreateNewModal from "./CreateNewModal/CreateNewModal";

function MboLfsStoreList() {
	const navigate = useNavigate();
	const [stores, setStores] = useState([]);
	const { loadingOpen, setLoadingOpen } = GlobalState();
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const [publish, setPublish] = useState(false);
	const [selectedStore, setSelectedStore] = useState();
	const [createModalOpen, setCreateModalOpen] = useState(false);

	const createModalClick = () => {
		setCreateModalOpen(true);
	};
	const handleStoreSelect = (selectedStore) => {
		navigate(`${mbolfsSelectedStorepageUrl}?store=${selectedStore.id}`);
	};

	const fetchStores = async () => {
		await axios
			.get("/api/mbo_store_v2/mbo-store")
			.then((res) => {
				if (res.status === 200) {
					console.log(res.data);
					setLoadingOpen(true);
					setStores(res.data);
					setLoadingOpen(false);
				}
			})
			.catch((err) => {
				console.log("error", err);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "unable to fetch stores",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setLoadingOpen(false);
			});
	};
	const publishStore = async (id, body) => {
		setLoadingOpen(true);
		await axios
			.put(`/api/ebo_store_v4/publish_store/${id}`, body)
			.then((res) => {
				if (res.status === 200) {
					console.log(res.data);
					let filterStore = stores.filter((store) => store.store_code !== id);
					filterStore.unshift(body);
					setStores(filterStore);
					setLoadingOpen(false);
					setPublish(false);
				}
			})
			.catch((err) => {
				console.log("error", err);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "unable to fetch stores",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setLoadingOpen(false);
			});
	};

	useEffect(() => {
		fetchStores();
	}, []);

	const handleDownloadClick = async () => {
		setLoadingOpen(true);
		try {
			await axios.get("/api/ebo_store_v4/download_stores", { responseType: "blob" }).then((res) => {
				console.log(res);
				const url = URL.createObjectURL(res.data);
				FileSaver.saveAs(url, "Ebo_store_details.xlsx");
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "Downloaded the all store details",
					severity: "success",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			});
		} catch (error) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "unable to fetch stores",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
		}
		setLoadingOpen(false);
	};

	const handleConfirmPublish = () => {
		const body = {
			...selectedStore,
			published_date: new Date(),
			status: statusOptions.PUBLISHED.key,
		};
		publishStore(selectedStore.store_code, body);
	};

	const handlePublishClick = (e, row) => {
		e.stopPropagation();
		setSelectedStore(row);
		setPublish(true);
	};

	const columns = [
		{
			field: "code",
			headerName: "STORE CODE",
			renderCell: (params) => params.row?.code,
			width: 150,
		},
		{
			field: "site_name",
			headerName: "SITE NAME",
			width: 250,
			renderCell: (params) => params.row?.store_full_name?.toUpperCase(),
		},
		{
			field: "state",
			headerName: "STATE",
			width: 170,
			renderCell: (params) => params.row?.state?.toUpperCase(),
		},
		{
			field: "city",
			headerName: "CITY",
			width: 170,
			renderCell: (params) => params.row?.city?.toUpperCase(),
		},
		{
			field: "pincode",
			headerName: "PINCODE",
			width: 170,
			renderCell: (params) => params.row?.pincode,
		},
		publishTableColumn(handlePublishClick, false),
	];

	const handleModalclose = () => {
		setPublish(false);
	};

	return (
		<Box direction="row" width="100%" justifyContent="space-around" p={2} boxSizing={"border-box"}>
			<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} pt={2}>
				<Stack direction={"row"}>
					<Button
						startIcon={<ArrowBackIosIcon fontSize="20" />}
						size="large"
						onClick={() => navigate("/explore/mbo_lfs_stores")}
					/>
					<Typography variant="h4" align={"left"}>
						MBO / LFS STORES
					</Typography>
				</Stack>
				<Stack direction={"row"} display={"flex"} justifyContent="space-evenly" width={"30%"} justifyItems={"flex-end"}>
					<Button
						autoFocus
						variant="contained"
						onClick={() => handleDownloadClick()}
						startIcon={<DownloadIcon />}
						size="medium">
						Download
					</Button>
					<Button autoFocus variant="contained" onClick={createModalClick} startIcon={<AddIcon />} size="medium">
						Create New Store
					</Button>
				</Stack>
			</Stack>
			<Stack pt={3} height={420}>
				<DataGrid
					rows={stores}
					columns={columns}
					sx={{
						"& .MuiDataGrid-row": {
							cursor: "pointer",
						},
					}}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 10,
							},
						},
					}}
					pageSizeOptions={[10, 20, 30]}
					onRowClick={({ row }) => handleStoreSelect(row)}
				/>

				<CreateNewModal modal={createModalOpen} setModal={setCreateModalOpen} fetchStores={fetchStores} />
			</Stack>
			{publish && (
				<Dialog maxWidth="sm" fullWidth open={publish} onClose={handleModalclose}>
					<Box p={2}>
						<Typography variant="h6">{`Do you want to publish ${selectedStore.store_name} store`}</Typography>

						<DialogActions sx={{ pt: 2 }}>
							<Button variant="outlined" sx={{ justifySelf: "center" }} onClick={handleModalclose}>
								Cancel
							</Button>
							<Button variant="contained" onClick={handleConfirmPublish} sx={{ justifySelf: "center" }} type="submit">
								Publish
							</Button>
						</DialogActions>
					</Box>
				</Dialog>
			)}
		</Box>
	);
}

export default MboLfsStoreList;
